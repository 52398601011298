import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import React, { Component, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { truncate } from "./view-util";

export default function Project() {
  const params = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const state = { project: null };

  const tmp = localStorage.getItem("projects");
  const projects = tmp ? JSON.parse(tmp) : [];
  const projectId = params["id"] || null;
  state.project = projectId
    ? projects.filter((p) => p.id === projectId)[0]
    : null;

  const linkBlock = ({title: title, subtitle: subtitle, to:to, icon:icon}) => {
    return (
      <div className="column is-10-widescreen is-12-desktop is-12-tablet is-12-mobile">
        <Link className="box" to={to}>
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <figure class="image is-hidden-mobile box is-shadowless has-background-info-90 p-3 ">
              <FontAwesomeIcon
                  className="is-rounded has-text-primary"
                  icon={byPrefixAndName.fal[icon]}
                  size="2x"
                />
              </figure>
            </div>
            <div className="column is-12">
              <h3 className="title has-text-primary">
                {title} &nbsp;{" "}
                <FontAwesomeIcon
                  className=""
                  icon={byPrefixAndName.fas["arrow-right"]}
                  size="m"
                />
              </h3>
              <p className="subtitle mt-2">
                {subtitle}
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const tutorialCard = ({videoUrl: videoUrl, title:title}) => {
    return(
    <div class="card mb-5">
              <div class="card-image">
                <figure class="image is-5by3">
                  <video controls>
                    <source
                      src={videoUrl}
                      type="video/mp4"
                    />
                  </video>
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-24x24">
                      <img
                        src="https://www.datocms-assets.com/20955/1579120403-tobin.jpg?auto=compress&fit=contain&fm=jpg&invert=false&sat=-5&w=1080"
                        alt="Placeholder image"
                      />
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="has-text-weight-semibold is-5 mb-0">{title}</p>
                    <p class="subtitle is-7 pt-1">by Tobin Harris</p>
                  </div>
                </div>
              </div>
            </div>)
  }

  const saveProjectChanges = () => {
    const updatedProjects = projects.map(p => 
      p.id === projectId ? { ...p, ...editedProject } : p
    );
    localStorage.setItem("projects", JSON.stringify(updatedProjects));
    state.project = { ...state.project, ...editedProject };
    setIsEditing(false);
  };

  return (
    <>
      <div className={`modal ${isEditing ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setIsEditing(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit Project</p>
            <button 
              className="delete" 
              aria-label="close" 
              onClick={() => setIsEditing(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label has-text-dark">Project Name</label>
              <div className="control">
                <input 
                  className="input"
                  type="text"
                  value={editedProject?.name || ''}
                  onChange={(e) => setEditedProject({...editedProject, name: e.target.value})}
                />
              </div>
            </div>
            <div className="field">
              <label className="label has-text-dark">Description</label>
              <div className="control">
                <textarea 
                  className="textarea"
                  value={editedProject?.description || ''}
                  onChange={(e) => setEditedProject({...editedProject, description: e.target.value})}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary has-text-white" onClick={saveProjectChanges}>Save changes</button>
            <button className="button has-text-grey" onClick={() => setIsEditing(false)}>Cancel</button>
          </footer>
        </div>
      </div>

      <section className="container content is-fullhd pl-6 pr-6">
        <div className="columns pt-6">
          <div className="column is-9-widescreen is-8-desktop">
            <div className="content mb-6">
              <div className="is-flex is-justify-content-space-between is-align-items-center">
                <h1 className="title">{state.project.name} <button 
                  className="button is-text " 
                  onClick={() => {
                    setEditedProject({
                      name: state.project.name,
                      description: state.project.description
                    });
                    setIsEditing(true);
                  }}
                >
                  <FontAwesomeIcon icon={byPrefixAndName.fal["edit"]} />
                  <span className="ml-2">Edit</span>
                </button></h1>
                
              </div>
              <p className="is-size-4 has-text-dark">
                {truncate(state.project.description, 150)}
              </p>
              {state.project.previewId ? (
                      <p className="">
                      <div class="is-size-7 box is-shadowless p-2 has-background-warning-90 has-text-warning-20"><b>Share preview link</b>:&nbsp; 
                        <a className="has-text-warning-20" href={`/preview/${state.project.userId}/${state.project.previewId}`}>{window.location.protocol}//{window.location.host}
                        /preview/{state.project.userId}/{state.project.previewId}</a>
                      </div></p>
                    ) : null}
            </div>

            
            {linkBlock({title: "Plan the big picture", subtitle: "Outline your strategic decisions.", to: `/projects/${projectId}/strategy`, icon: "diagram-lean-canvas"})}
            {linkBlock({title: "Design the experience", subtitle: "Visualise your app features.", to: `/projects/${projectId}/flows`, icon: "grid-dividers"})}            
            {linkBlock({title: "Model your growth", subtitle: "Prepare to grow your revenue and userbase.", to: `/projects/${projectId}/growth`, icon: "chart-line-up"})}
            {linkBlock({title: "Estimate the cost", subtitle: "Learn what it will take to build.", to: `/projects/${projectId}/estimate`, icon: "grid-dividers"})}

           
          </div>
          <div className="column">
            <h3>Learn</h3>
            {tutorialCard({videoUrl:'https://appflows.ai/lib_UjuOgxWdHXIbgDWG/2o6eyvjaqr9ifi06.mp4', title:'Creating your first flows'})}
            {tutorialCard({videoUrl:'https://appflows.ai/lib_UjuOgxWdHXIbgDWG/rpyfy6wq4boj4ojc.mp4', title:'The growth planner'})}
            
          </div>
        </div>
      </section>
    </>
  );
}
