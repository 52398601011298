
export function parseFlows(text, settings){
  let newFlows = [];
  let currentFlow = null;
  const lines = text.split(/\n/);
  let tmpSettings = { ...settings };
  tmpSettings.showPoints = false;
  tmpSettings.showPrice = false;
  let totalPoints = 0;
  let totalPrice = 0;
  let lastParsed = null;
  let newStory = null;
  let title = null;
  let description = null;
  //setSettings(tmpSettings);
  lines.forEach((line, i) => {
    //ignore commented line with "//""
    if (/^\s*\/\//.test(line)) return;
    // capture heading 1
    if (/^#[^#]+/.test(line)) {
      title = line.match(/^#(.+)/)[1];
      lastParsed = "H1";
      // capture heading 2
    } else if (/^##.+/.test(line)) {
      if (currentFlow) newFlows.push(currentFlow);
      currentFlow = {
        name: "New Flow",
        description: "",
        stories: [],
        lineNo: i + 1,
      };
      currentFlow.name = line.match(/^##(.+)/)[1];
      lastParsed = "H2";
    } else if (/^\w+/.test(line) && lastParsed) {
      if (lastParsed === "H1") description = line;
      else if (lastParsed === "H2")
        if (currentFlow) currentFlow.description = line;
    } else {
      if (/@showPoints/.test(line)) {
        tmpSettings.showPoints = true;
        //setSettings(tmpSettings);
        return;
      } else if (/@showPrice/.test(line)) {
        tmpSettings.showPrice = true;
        //setSettings(tmpSettings);
        return;
      } else if (/@showTable/.test(line)) {
        tmpSettings.showTable = true;
        //setSettings(tmpSettings);
        return;
      } else if (/@pointPrice \d+/.test(line)) {
        const matches = line.match(/@pointPrice (\d+)/);
        if (matches.length === 2) {
          tmpSettings.pointPrice = parseInt(matches[1]);
          //setSettings(tmpSettings);
        }
        return;
      } else if (currentFlow && line.length > 0) {
        line = line
          .replace(/\*\*(.*?)\*\*/, "<b>$1</b>")
          //.replace(/\*(.*?)\*/, "<i>$1</i>") //TODO: Why does it delete the flow!?
          .replace(/~~(.*?)~~/, "<strike>$1</strike>")
          .replace(/_(.*?)_/, "<i>$1</i>");
        const lineWithPointsMatches = line.match(
          /^\s*[\*|\-]\s(.+)(\b\d+)pt/
        );
        const lineMatches = line.match(/^\s{0,1}[\*|\-]\s(.+)/);
        const acceptanceCriteriaMatch = line.match(/^(\s{2,4}|\t)\*\s(.+)/);

        if (lineWithPointsMatches || lineMatches) {
          if (newStory) {
            currentFlow.stories.push(newStory);
            newStory = null;
          }
          const matches = lineWithPointsMatches || lineMatches;
          const imgRegex = /#[\w-]+/g;
          const line = matches[1];
          const images = line ? line.match(imgRegex) : [];
          const imageCount = images ? images.length : 1;
          const titleMatches = line.match(/^([^:]+):(.*)/);
          
          var storyText = line
          var titleText = null
          
          if(titleMatches){
            storyText = titleMatches[2]
            titleText = titleMatches[1]
          }// [null, story.text];
          

          newStory = {
            text: storyText,
            title: titleText,
            size: 0,
            acceptanceCriteria: [],
            imageCount: imageCount,
            lineNo: i + 1,
          };
          if (matches.length === 3) {
            newStory.size = parseInt(matches[2]);
            totalPoints += newStory.size;
            totalPrice = totalPoints * tmpSettings.pointPrice;
          }
        } else if (acceptanceCriteriaMatch && newStory) {
          if (
            acceptanceCriteriaMatch.length === 3 &&
            newStory.acceptanceCriteria
          ) {
            newStory.acceptanceCriteria.push(acceptanceCriteriaMatch[2]);
          }
        }
      }
    }

    if (newStory) {
      currentFlow.stories.push(newStory);
      newStory = null;
    }

    if (i === lines.length - 1 && i !== 0 && currentFlow) {
      newFlows.push(currentFlow);
    }
  });
  return {
    title: title,
    description: description,
    flows: newFlows,
    totalPoints: totalPoints,
    totalPrice: totalPrice,
    settings: tmpSettings
  }
}