import React, { useState, useEffect } from "react";
import Editor from "@toast-ui/editor";
import { useParams } from "react-router-dom";
import imageKeywords from "./imageIndex.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
//import "cooltipz-css";
import {
  AreaChart,
  LineChart,
  Line,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const GrowthBuilder = () => {
  const params = useParams();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [growthModel, setGrowthModel] = useState({
    model: "subscribers",
    appStoreViews: 65876,
    targetUsers: 1000,
    paymentPerCustomerPerYear: 35.99,
    revenuePerCustomerPerYear: 25.49,
    isUnderOneMillion: true,
    appStoreTax: 0.15,
    ignoreAppStoreTax: false,
    appStoreConversionRate: 0.2,
    activationRate: 0.42,
    trialStartRate: 0.11, //of installs, now app store views
    subscriptionRate: 0.69, //of installs
    costPerDownload: 1.4,
    oneDayRetention: 0.4,
    oneWeekRetention: 0.4,
    oneMonthRetention: 0.05,
  });
  const [strings, setJargon] = useState({
    appStoreViews: {
      jargon: "App Store Conversion Rate",
      layman: "How many will download?",
    },
  });
  const [growthModelPlus, setGrowthModelPlus] = useState({
    funnel: [
      { name: "View App Store Page", value: 1 },
      { name: "Install", value: 0.1 },
      { name: "Activate", value: 0.4 },
      { name: "Start Trial", value: 0.3 },
      { name: "Subscribe", value: 0.35 },
    ],
  });
  const data = [
    { name: "Saw It", uv: growthModel.appStoreViews, pc: 100 },
    {
      name: "Installed App",
      uv: growthModel.appStoreViews * growthModel.appStoreConversionRate,
      pc: 100 * growthModel.appStoreConversionRate,
    },
    {
      name: "Started Trial",
      uv:
        growthModel.appStoreViews *
        growthModel.appStoreConversionRate *
        growthModel.activationRate *
        growthModel.trialStartRate,
      pc:
        100 *
        growthModel.appStoreConversionRate *
        growthModel.activationRate *
        growthModel.trialStartRate,
    },
    {
      name: "Subscribed",
      uv:
        growthModel.appStoreViews *
        growthModel.appStoreConversionRate *
        growthModel.activationRate *
        growthModel.trialStartRate *
        growthModel.subscriptionRate,
      pc:
        100 *
        growthModel.appStoreConversionRate *
        growthModel.activationRate *
        growthModel.trialStartRate *
        growthModel.subscriptionRate,
    },
  ];

  useEffect(() => {
    calculateInstallsNeeded();
  }, []);

  const calculateInstallsNeeded = () => {
    // calculate how many app store views we need to get this many target users
    const installsNeeded =
      parseInt(growthModel.targetUsers) /
      (growthModel.trialStartRate * growthModel.subscriptionRate);

    const appStoreViewsNeeded =
      installsNeeded / growthModel.appStoreConversionRate;

    const res = calculateTakeHome(
      growthModel.paymentPerCustomerPerYear,
      growthModel.isUnderOneMillion,
      growthModel.ignoreAppStoreTax
    );

    setGrowthModel((growthModel) => ({
      ...growthModel, // Spread the previous state
      appStoreViews: appStoreViewsNeeded,
      revenuePerCustomerPerYear: res.takeHome,
      appStoreTax: res.appStoreTax,
    }));
  };

  const updateTargetUsers = (e) => {
    if (!e.target.value) return;

    setGrowthModel((growthModel) => ({
      ...growthModel, // Spread the previous state
      targetUsers: parseInt(e.target.value), // Override the value of key1
    }));

    calculateInstallsNeeded();
  };

  // const updateAppStoreViews = (e) => {
  //   setGrowthModel((growthModel) => ({
  //     ...growthModel, // Spread the previous state
  //     appStoreViews: parseFloat(e.target.value), // Override the value of key1
  //   }));
  // };

  const updateCostPerInstall = (e) => {
    setGrowthModel((growthModel) => ({
      ...growthModel, // Spread the previous state
      costPerDownload: parseFloat(e.target.value), // Override the value of key1
    }));
    calculateInstallsNeeded();
  };

  const updateKey = (k, val, fieldValue) => {
    if (val === null) return;
    growthModel[k] = val;
    setGrowthModel((growthModel) => ({
      ...growthModel, // Spread the previous state
      k: val, // Override the value of key1
    }));
    //if(fieldValue) document.getElementById(k).value = fieldValue
    calculateInstallsNeeded();
  };

  const formatMoney = (val) => {
    if (!val) return "nothing";
    const nVal = Number(val);
    return `£${Number(nVal).toLocaleString("en-GB", {
      minimumFractionDigits: nVal < 100 ? 2 : 0,
      maximumFractionDigits: nVal < 100 ? 2 : 0,
    })}`;
  };

  const formatInt = (val) => {
    return parseInt(val).toLocaleString("en-GB");
  };
  const formatFloat = (val) => {
    return val.toLocaleString("en-GB");
  };

  const calculateTakeHome = (
    subscriptionFee,
    isUnderOneMillion,
    ignoreAppStoreTax
  ) => {
    if (!subscriptionFee)
      return {
        subscriptionFee: 0,
        takeHome: 0,
        appStoreFees: 0,
        vat: 0,
        appStoreTax: growthModel.appStoreTax,
      };

    if (ignoreAppStoreTax) {
      return {
        subscriptionFee: parseFloat(subscriptionFee).toFixed(2),
        takeHome: parseFloat(subscriptionFee).toFixed(2),
        appStoreFees: 0,
        vat: 0,
        appStoreTax: 0,
      };
    }

    subscriptionFee = parseFloat(subscriptionFee);

    const VAT_RATE = 0.2;
    const APP_STORE_FEE_UNDER_ONE_MILLION = 0.15;
    const APP_STORE_FEE_OVER_ONE_MILLION = 0.3;

    // Calculate VAT
    const vat = (subscriptionFee * VAT_RATE) / (1 + VAT_RATE);

    // Price excluding VAT
    const priceExcludingVAT = subscriptionFee - vat;

    // Determine the app store fee rate
    const appStoreFeeRate = isUnderOneMillion
      ? APP_STORE_FEE_UNDER_ONE_MILLION
      : APP_STORE_FEE_OVER_ONE_MILLION;

    // Calculate app store fees
    const appStoreFees = priceExcludingVAT * appStoreFeeRate;

    // Calculate take-home amount
    const takeHome = priceExcludingVAT - appStoreFees;

    return {
      subscriptionFee: subscriptionFee.toFixed(2),
      takeHome: takeHome.toFixed(2),
      appStoreFees: appStoreFees.toFixed(2),
      vat: vat.toFixed(2),
      appStoreTax: appStoreFeeRate,
    };
  };

  const growthDescription = () => {
    return (
      <>
        <h3 className="title mb-5 is-size-4">3. How does it play out?</h3>
        <div className="content sgrowth-results">
          <div className="columns is-multiline">
            <div className="column is-6">
              <div className="box is-size-5">
                Each paying customer will cost you{" "}
                <b>
                  {formatMoney(
                    (growthModel.appStoreViews *
                      growthModel.appStoreConversionRate *
                      growthModel.costPerDownload) /
                      growthModel.targetUsers
                  )}
                </b>{" "}
                to acquire.
              </div>
            </div>
            <div className="column is-6">
              <div className="box is-size-5">
                In total you'll{" "}
                <b>
                  spend{" "}
                  {formatMoney(
                    growthModel.appStoreConversionRate *
                      growthModel.appStoreViews *
                      growthModel.costPerDownload
                  )}{" "}
                </b>
                and{" "}
                <b>
                  bank{" "}
                  {formatMoney(
                    growthModel.revenuePerCustomerPerYear *
                      growthModel.targetUsers
                  )}
                </b>
                .
              </div>
            </div>
            <div className="column is-12">
              <div className="box is-size-5">
                This gives a return of{" "}
                <b>
                  {formatMoney(
                    growthModel.revenuePerCustomerPerYear *
                      growthModel.targetUsers -
                      growthModel.appStoreConversionRate *
                        growthModel.appStoreViews *
                        growthModel.costPerDownload
                  )}
                </b>
                , or{" "}
                {formatInt(
                  ((growthModel.revenuePerCustomerPerYear *
                    growthModel.targetUsers -
                    growthModel.appStoreConversionRate *
                      growthModel.appStoreViews *
                      growthModel.costPerDownload) /
                    (growthModel.revenuePerCustomerPerYear *
                      growthModel.targetUsers)) *
                    100
                )}
                %. ROAS would be{" "}
                {Number(
                  (growthModel.revenuePerCustomerPerYear *
                    growthModel.targetUsers) /
                    (growthModel.appStoreConversionRate *
                      growthModel.appStoreViews *
                      growthModel.costPerDownload)
                ).toFixed(1)}
                x
              </div>
            </div>
          </div>
          <h4>
            You need <span>{formatInt(growthModel.appStoreViews)}</span> app
            store page views
          </h4>
          <p>
            You want {formatInt(growthModel.targetUsers)} paying or active
            users, so you'll need to use some of the{" "}
            {formatMoney(
              growthModel.appStoreConversionRate *
                growthModel.appStoreViews *
                growthModel.costPerDownload
            )}{" "}
            to drive people to your app store pages. Think about how you'll do
            this. Will it be this with paid advertising, social media, events or
            perhaps organic growth?
          </p>
          <h4>
            You'll get{" "}
            <span>
              {formatInt(
                growthModel.appStoreViews * growthModel.appStoreConversionRate
              )}
            </span>{" "}
            downloads
          </h4>
          <p>
            This assuming that{" "}
            {formatFloat(growthModel.appStoreConversionRate * 100)}% download
            after viewing your page. Again, you might use some of your{" "}
            {formatMoney(
              growthModel.appStoreConversionRate *
                growthModel.appStoreViews *
                growthModel.costPerDownload
            )}{" "}
            to ensure that your app store pages convince people to download. A
            poor conversion rate is 5%, a good one is 10% and an excellent one
            is 20%. Read <a href="#">How to get more downloads</a>.
          </p>
          <h4>
            <span>
              {formatInt(
                growthModel.appStoreViews *
                  growthModel.appStoreConversionRate *
                  growthModel.trialStartRate
              )}{" "}
            </span>{" "}
            will start a trial
          </h4>
          <p>
            This is based on a trial start rate of{" "}
            {formatFloat(growthModel.trialStartRate * 100)}%. To check if this
            number is realistic, consider that 2% is a low trial-start rate, 10%
            is good, and 15% is excellent.{" "}
          </p>
          <h4>
            {formatInt(
              growthModel.appStoreViews *
                growthModel.appStoreConversionRate *
                growthModel.trialStartRate *
                growthModel.subscriptionRate
            )}{" "}
            will purchase.
          </h4>
          <p>
            This is based on {formatFloat(growthModel.subscriptionRate * 100)}%{" "}
            converting from trial to paid. For subscription apps, 10% is low,
            35% is good, and 75% is excellent.
          </p>{" "}
        </div>
      </>
    );
  };

  const createToggle = (current, low, med, high, words, key) => {
    words = words ?? ["Low", "Medium", "High"];
    var selected = "low";
    if (current <= low) {
      selected = "low";
    } else if (current < high) {
      selected = "med";
    } else {
      selected = "high";
    }

    return (
      <div className="tabs is-mini is-toggle is-toggle-rounded is-small">
        <ul>
          <li className={selected === "low" ? "is-active" : ""}>
            <a className="pt-1 pb-1 has-background-success has-text-white">
              <span>{words[0]}</span>
            </a>
          </li>
          <li className={selected === "med" ? "is-active" : ""}>
            <a className="pt-1 pb-1 has-background-warning has-text-white">
              <span>{words[1]}</span>
            </a>
          </li>
          <li className={selected === "high" ? "is-active" : ""}>
            <a className="pt-1 pb-1 has-background-danger has-text-white">
              <span>{words[2]}</span>
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const MyForm = () => {
    return (
      <>
        <h3 className="title mb-4 is-size-4">2. Define your model</h3>
        <table className="table">
          <tr>
            <th colspan="4">Goals and Costs</th>
          </tr>

          <tr>
            <td>
              How many subscribers do you want?{" "}
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="Or, how many people do you wish to have as active users or paying users?"
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div classaName="field has-addons">
                <p className="control is-expanded">
                  <input
                    id="targetUsers"
                    className="input"
                    type="number"
                    placeholder="Number of users"
                    defaultValue={growthModel.targetUsers}
                    onChange={(e) => updateTargetUsers(e)}
                  />
                </p>
              </div>
            </td>
            <td></td>
            <td width="150px"></td>
          </tr>
          <tr>
            <td>
              How much money will you earn from each subscriber in the first
              year, on average?{" "}
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="This is after app store fees? E.g. If your apps annual subscription is £35.99, then deduct 15% Apple or Google fees."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static">£</a>
                </p>
                <p className="control is-expanded">
                  <input
                    id="paymentPerCustomerPerYear"
                    className="input"
                    type="number"
                    placeholder="Subscription price"
                    defaultValue={growthModel.paymentPerCustomerPerYear}
                    onChange={(e) => {
                      updateKey("paymentPerCustomerPerYear", e.target.value);
                    }}
                  />
                </p>
              </div>
            </td>
            <td></td>
            <td width="150px">
              {createToggle(
                growthModel.paymentPerCustomerPerYear,
                10,
                36,
                50,
                ["Cheap", "Average", "High"],
                "paymentPerCustomerPerYear"
              )}
            </td>
          </tr>
          <tr>
            <td>
              Does your app earn less than $1m and therfore qualify for lower
              app store fees?{" "}
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="Google and Apple both allow apps that earn under $1m to have 15% app store fees."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="control">
                <label className="radio">
                  <input
                    type="radio"
                    name="foobar"
                    onClick={() => {
                      updateKey("ignoreAppStoreTax", false);
                      updateKey("isUnderOneMillion", true);
                    }}
                    checked={growthModel.appStoreTax === 0.15}
                  />
                  &nbsp;Yes
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="foobar"
                    onClick={() => {
                      updateKey("ignoreAppStoreTax", false);
                      updateKey("isUnderOneMillion", false);
                    }}
                    checked={growthModel.appStoreTax === 0.3}
                  />
                  &nbsp;No
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="foobar"
                    onClick={() => updateKey("ignoreAppStoreTax", true)}
                    checked={growthModel.ignoreAppStoreTax === true}
                  />
                  &nbsp;Ignore
                </label>
              </div>
            </td>
            <td></td>
            <td width="150px">
              You'll take home {growthModel.revenuePerCustomerPerYear} after
              paying {growthModel.appStoreTax * 100}% app store tax and VAT.
            </td>
          </tr>
          <tr>
            <td>
              What will is the marketing cost to get a single download?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="The only way to really find out is to do some marketing. If you don't know yet, £4 is a safe bet, but it could be as low as £0.80 or as high as £8.00."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static">£</a>
                </p>
                <p className="control is-expanded">
                  <input
                    id="costPerDownload"
                    className="input"
                    type="number"
                    placeholder="Cost Per Download"
                    defaultValue={Number(growthModel.costPerDownload).toFixed(
                      2
                    )}
                    onKeyUp={updateCostPerInstall}
                  />
                </p>
              </div>
            </td>
            <td></td>
            <td>
              {createToggle(
                growthModel.costPerDownload,
                1.5,
                4,
                6,
                ["Cheap", "Average", "High"],
                "costPerDownload"
              )}
            </td>
          </tr>
          <tr>
            <th colspan="">Your Download Funnel</th>
            <th></th>
            <th className="has-text-weight-normal is-size-7"></th>
            <th></th>
          </tr>
          <tr>
            <td>
              What % wiewed your app store page?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="The % of people that see your app store page. This is the start of your funnel so it's 100%."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static disabled">%</a>
                </p>
                <p className="control is-expanded">
                  <input
                    className="input has-background-white-ter"
                    type="number"
                    defaultValue={100}
                    disabled={true}
                    readOnly
                  />
                </p>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              What % will install your app?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="Typically 5%-60%.For every 100 people that view your app store page, only a % will actually download. If your app solves a real problem and your app store page is shit hot, pick 30%."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static">%</a>
                </p>
                <p className="control is-expanded">
                  <input
                    id="appStoreConversionRate"
                    className="input"
                    type="number"
                    placeholder="Cost Per Download"
                    defaultValue={growthModel.appStoreConversionRate * 100}
                    onChange={(e) => {
                      updateKey("appStoreConversionRate", e.target.value / 100);
                    }}
                  />
                </p>
              </div>
            </td>
            <td></td>
            <td>
              {createToggle(
                growthModel.appStoreConversionRate,
                0.05,
                0.1,
                0.2,
                ["Easy", "Medium", "Hard"],
                "appStoreConversionRate"
              )}
            </td>
          </tr>
          <tr>
            <th colspan="">Your Subscriber Funnel</th>
            <th></th>
            <th className="has-text-weight-normal is-size-7"></th>
            <th></th>
          </tr>
          <tr>
            <td>
              What % will launch your app?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="It's simpler to assume 100% of people that download the app actually launch it."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static disabled">%</a>
                </p>
                <p className="control is-expanded">
                  <input
                    className="input has-background-white-ter"
                    type="number"
                    defaultValue={100}
                    disabled={true}
                    readOnly
                  />
                </p>
              </div>
            </td>
            <td>
              <span className="is-size-7"></span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              What % will start a trial?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="Typically 10%-75% of people who install will start a trial."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static">%</a>
                </p>
                <p className="control is-expanded">
                  <input
                    id="trialStartRate"
                    className="input"
                    type="number"
                    placeholder="Trial Start Rate"
                    defaultValue={Number(
                      growthModel.trialStartRate * 100
                    ).toFixed(2)}
                    onChange={(e) => {
                      updateKey("trialStartRate", e.target.value / 100);
                    }}
                  />
                </p>
              </div>
            </td>
            <td>
              <span className="is-size-7"></span>
            </td>
            <td>
              {createToggle(
                growthModel.trialStartRate,
                0.02,
                0.1,
                0.15,
                ["Easy", "Medium", "Hard"],
                "trialStartRate"
              )}
            </td>
          </tr>
          <tr>
            <td>
              What % will subscribe?&nbsp;
              <span
                className="cooltipz--bottom cooltipz--medium"
                aria-label="How many installers actually purchased? This is typically 10%-75%."
              >
                <FontAwesomeIcon
                  className="is-color-info"
                  icon={byPrefixAndName.fas["circle-question"]}
                />
              </span>
            </td>
            <td>
              <div className="field has-addons">
                <p className="control">
                  <a className="button is-static">%</a>
                </p>
                <p className="control is-expanded">
                  <input
                    id="subscriptionRate"
                    className="input"
                    type="number"
                    placeholder="Activation Rate"
                    defaultValue={Number(
                      growthModel.subscriptionRate * 100
                    ).toFixed(2)}
                    onChange={(e) => {
                      updateKey("subscriptionRate", e.target.value / 100);
                    }}
                  />
                </p>
              </div>
            </td>
            <td>
              <span
                className="is-size-7 cooltipz--bottom cooltipz--medium"
                aria-label="This is the overall % of installers that bought something, which can be useful to know when checking against benchmarks. "
              >
                {formatFloat(
                  growthModel.trialStartRate *
                    growthModel.subscriptionRate *
                    100
                )}
                %
              </span>
            </td>
            <td>
              {createToggle(
                growthModel.subscriptionRate,
                0.1,
                0.35,
                0.65,
                ["Easy", "Medium", "Hard"],
                "subscriptionRate"
              )}
            </td>
          </tr>
        </table>
      </>
    );
  };

  const goalUI = () => {
    return (
      <>
        <h3 className="title mb-5 is-size-4">
          1. What's your marketing goal right now?
        </h3>
        <article class="message is-warning">
          <div class="message-body">
            We're in Beta, so Subscribers is only working right now.
          </div>
        </article>
        <table className="table mb-5">
          <tr>
            <td className="pr-3 has-text-centered">
              <input
                type="radio"
                className="radio is-medium"
                name="type"
                checked={growthModel.model === "subscribers" ? true : false}
                onClick={() => updateKey("model", "subscribers")}
              />
            </td>
            <td className="is-size-5 pr-5 has-text-weight-semibold">
              Subscribers
            </td>
            <td className="is-color-grey">
              You want new subscribers for your subscription app
            </td>
          </tr>
          <tr className="pb-3">
            <td className="pr-3 has-text-centered">
              <input
                type="radio"
                className="radio is-medium"
                name="type"
                checked={growthModel.model === "users" ? true : false}
                onClick={() => updateKey("model", "users")}
              />
            </td>
            <td className="is-size-5 title pr-5 has-text-weight-semibold">
              Users
            </td>
            <td className="is-color-grey">
              You just want to get some people using your app
            </td>
          </tr>
          <tr className="pb-3">
            <td className="pr-3 has-text-centered">
              <input
                type="radio"
                className="radio is-medium"
                name="type"
                checked={growthModel.model === "buyers" ? true : false}
                onClick={() => updateKey("model", "buyers")}
              />
            </td>
            <td className="is-size-5 title pr-5 has-text-weight-semibold">
              Buyers
            </td>
            <td className="is-color-grey">
              You need people to buy products or services in your app.
            </td>
          </tr>
        </table>
      </>
    );
  };

  return (
    <div className="section growth">
      <h1 className="title">Growth Plan</h1>
      <p className="pt-3 content has-text-dark">Use this to model your app growth based on advertising revenues. More to come as we flesh out the BETA.</p>
      <div className="columns mt-5 mb-5">
        <div className="column is-7">
          <div className="box">
            {goalUI()}
            {MyForm()}
          </div>
        </div>
        <div className="column is-5 content">
          <div className="box">
            <p className="pt-0">{growthDescription()}</p>
            <h4 className="title pt-3 pb-3 has-text-centered">
              The new user funnel
            </h4>
            <AreaChart width={500} height={400} data={data}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1965fd" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#1965fd" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPc" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Line type="monotone" dataKey="uv" stroke="#1965fd" />

              <XAxis dataKey="name" />
              <YAxis dataKey="pc" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#1965fd"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Area
                type="monotone"
                dataKey="pc"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPc)"
              />
            </AreaChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthBuilder;
