import React, { Component, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import introJs from "intro.js/intro";
import Editor from "@toast-ui/editor";
import imageKeywords from "./imageIndex.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { truncate, formatCurrency, getPDF } from "./view-util.js";
import UserFlowView from "./components/UserFlowView.js";
import { parseFlows } from "./FlowLogic.js";

const Preview = () => {
  const [project, setProject] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [flowsDoc, setFlowsDoc] = useState(null);
  const params = useParams();
  const previewId = params["previewId"] || null;
  const userId = params["userId"] || null;

  const [settings, setSettings] = useState({
    pointPrice: 850,
    showPrice: false,
    showPoints: false,
    showTable: false,
  });

  const ref = null;

  const title = "";
  const description = "";
  const flows = [];
  const totalPoints = 0;
  const totalPrice = 0;

  if (!userId && !previewId) {
    setErrorText("No userId or previewId");
  }

  useEffect(() => {
    if (!userId && !previewId) return;
    fetch(
      `/api/preview/get?userId=${userId}&previewId=${previewId}`, //`/.netlify/functions/getPreview?userId=${userId}&previewId=${previewId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        
        if (response.ok) {
          const json = await response.json();
          const settings = {
            showPoints: false,
            showPrice: false,
            pointPrice: 0,
          };
          const parsed = parseFlows(json.flows.text, settings);
          setProject(json);
          setFlowsDoc(parsed);
          setErrorText(null);
        } else {
          setErrorText(response.message);
        }
      })
      //.error((err) => {setErrorText(err)})
      .catch((err) => {
        setErrorText(err.toString());
      });
  }, [previewId]);

  const meta = () => {
    const screenshotUrl = `https://image.thum.io/get/wait/12/${window.location.toString()}`;
    return (
      <Helmet>
        <title>{project?.name || "Loading"} - appflows.ai</title>
        <description>A mobile app specificaiton, strategy and wireframes for {project?.name}. {project?.description}. AppFlows is built by Pocketworks, the UK app developers and a digital product studio helping purpose-led businesses make apps that make a difference.</description>]
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${project?.name || "Loading"}`} />
        <meta property="og:url" content={`${window.location.toString()}`} />
        <meta property="og:image" content={screenshotUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${project?.name || "Loading"}`} />
        <meta name="twitter:url" content={`${window.location.toString()}`} />
        <meta name="twitter:image" content={screenshotUrl} />
      </Helmet>
    );
  };

  return (
    <main>
      {meta()}
      <div className="section container is-fluid">
        <div className="columns is-clearfix is-multiline">
          <div className={`column is-12 ${errorText ? "" : "is-hidden"}`}>
            <article className={`message is-danger`}>
              <div class="message-body">{errorText}</div>
            </article>
          </div>

          {flowsDoc ? (
            <>
              <div className="column is-9-widescreen is-9-desktop is-12-tablet has-max-width-60rem">
                <UserFlowView
                  settings={flowsDoc?.settings}
                  ref={ref}
                  getPDF={getPDF}
                  title={flowsDoc?.title}
                  description={flowsDoc?.description}
                  flows={flowsDoc?.flows}
                  imageKeywords={imageKeywords}
                  totalPoints={flowsDoc?.totalPoints}
                  totalPrice={flowsDoc?.totalPrice}
                  showPoints={flowsDoc?.settings.showPoints}
                  showPrice={flowsDoc?.settings.showPrice}
                  formatCurrency={formatCurrency}
                  project={project}
                  hideBigPicture={false}
                  isPreviewMode={true}
                />
              </div>
              <div className="column">
                <div className="pt-5"></div>
                <div className="has-background-white is-shadowless box ml-6 mt-3 content">
                  <p className="has-text-weight-semibold">What's this?</p>
                  <p>
                    You're looking at a specification created in AppFlows; a
                    FREE tool to help product leaders create app specifications
                    and mobile strategies, fast.
                  </p>
                  <p>Get your own FREE account by signing up above.</p>                  
                </div>
                <p className="is-size-6 m-6 has-text-grey has-text-right">AppFlows is made by <a href="https://pocketworks.co.uk">Pocketworks</a>, a mobile app specialist.<br/> We help purpose-led organisations take impactful digital products to market. </p>
              </div>
            </>
          ) : (
            <div className="column">Loading Preview</div>
          )}
        </div>
      </div>
    </main>
  );
};
export default Preview;
