export class ProjectRepository {
  
  loadProjects(){
    const tmp = localStorage.getItem("projects");
    const projects = tmp ? JSON.parse(tmp) : [];
    projects.map((p)=>{
      if(! p.flows){
        p.flows = {text: "# Insert Title\n\nUse the editor to the right to edit this document.\n\n## Onboarding Flow\n\nUsers need to get started with the apps\n\n* User can download app from the store\n* Users can login"}
      }
    })
    return projects;
  }
  saveProjects(myProjects){
    if (myProjects.length >= 0) {
      localStorage.setItem("projects", JSON.stringify(myProjects));
    } else {
      throw "No projects for some reason";
    }
  }

  saveProject(myProjects, myProj){
    const tmp = structuredClone(myProj)
    tmp["lastUpdated"] = Date.now()    
    myProjects.map((p, i) => {
      if (p.id === tmp.id) {
        // console.log(`Preview ${p.previewId} == ${project.previewId}`);
        myProjects[i] = tmp;
        this.saveProjects(myProjects);
        // setProjects(myProjects);
        // setProject(myProj);
        return;
      }
    });
  }

  duplicateProject(projectToDuplicate, allProjects) {
    // Create a deep clone of the project
    const duplicatedProject = structuredClone(projectToDuplicate);
    
    // Generate new ID for the duplicate
    duplicatedProject.id = crypto.randomUUID();
    
    // Modify the name to indicate it's a copy
    duplicatedProject.name = `${duplicatedProject.name} (Copy)`;

    duplicatedProject.previewId = null;
    
    // Create new array with the duplicated project
    const updatedProjects = [...allProjects, duplicatedProject];
    
    // Save to localStorage
    this.saveProjects(updatedProjects);
    
    return updatedProjects;
  }
}
