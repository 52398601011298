import React, { Component } from "react";

class Backup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectListString: ""
    };
  }

  componentDidMount() {
    var projectList = localStorage.getItem("projects");
    console.log(projectList)
    this.setState({projectListString: projectList})
  }

  restoreBackup(){
    var fallBack = localStorage.getItem("projects");    
    var toRestore = document.getElementById('restore-text').value
    console.log(toRestore)
    var parsedProjects = JSON.parse(toRestore);
    
    if (confirm(`Restore ${parsedProjects.length} projects? This will overwrite all existing data.`)) {
      localStorage.setItem("projects", toRestore);
      //this.setState({projectListString: fallBack})        
      alert("Restore Complete!")      
    } else {
      alert("Nothing restored.")      
    }
    
    
    
  }

  render() {
    return (
      <>
        <section className="container">
          <div className="content pt-6">
            <div className="columns">
              <div className="column is-6 box m-2">
                <h1>Backup</h1>
                <p>Copy the text from below and paste it somewhere safe.</p>
                <textarea style={{width: "100%"}} rows="20" defaultValue={this.state.projectListString} />
              </div>
              <div className="column is-6 box m-2">
                <h1>Restore</h1>
                <p>Paste in your saved text and hit restore.</p>
                <textarea id="restore-text" style={{width: "100%"}} rows="20"></textarea>
                <a className="button is-primary is-outlined" onClick={this.restoreBackup}>Restore Backup</a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Backup;
