/* existing imports */
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import { Link, createBrowserRouter, RouterProvider } from "react-router-dom";

import FlowBuilder from "./FlowBuilder";
import GrowthBuilder from "./GrowthBuilder";
import StrategyBuilder from "./StrategyBuilder";

import Projects from "./Projects";
import Project from "./Project";
import Backup from "./Backup";
import Preview from "./Preview";

import "./App.sass";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Projects key="projects" />,
      },
      {
        path: "projects",
        element: <Projects key="projects" />,
      },
      {
        path: "projects/backup",
        element: <Backup key="backup" />,
      },
      {
        path: "projects/:id",
        element: <Project key="project-overview" />,
      },
      {
        path: "projects/:id/flows",
        element: <FlowBuilder key="flow-builder" initialSelectedTab="flows-tab" />,
      },
      {
        path: "projects/:id/strategy",
        element: <FlowBuilder key="flow-builder" initialSelectedTab="strategy-tab" />,
      },
      {
        path: "projects/:id/strategy/v1",
        element: <StrategyBuilder key="strategy-builder" />,
      },
      {
        path: "projects/:id/estimate",
        element: <FlowBuilder key="flow-builder" initialSelectedTab="estimate-tab" />,
      },
       {
        path: "projects/:id/strategy/v1",
        element: <StrategyBuilder key="strategy-builder" />,
      },
      {
        path: "projects/:id/growth",
        element: <GrowthBuilder key="growth-builder" />,
      },
      {
        path: "preview/:userId/:previewId",
        element: <Preview key="preview" />,
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

