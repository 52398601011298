import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../App.sass";
import ShareDropdown from "../components/ShareDropdown";
import { ProjectRepository } from "../ProjectRepository.js";

export default function Root() {
  const netlifyIdentity = require("netlify-identity-widget");
  netlifyIdentity.init({
    container: "#overlay", // defaults to document.body
    locale: "en", // defaults to 'en'
  });
  netlifyIdentity.on("login", (user) => {
    setUser(user);
    netlifyIdentity.close();
  });
  netlifyIdentity.on("logout", () => {
    setUser(null);
    netlifyIdentity.close();
  });
  netlifyIdentity.on("init", () => netlifyIdentity.close());

  const params = useParams();
  // const tmp = localStorage.getItem("projects");
  const projectRepository = new ProjectRepository();
  const [projects, setProjects] = React.useState(
    projectRepository.loadProjects()
  );
  // const projects = tmp ? JSON.parse(tmp) : [];
  const projectId = params["id"] || null;
  const [project, setProject] = React.useState(
    projectId ? projects.filter((p) => p.id === projectId)[0] : null
  );
  const [user, setUser] = React.useState(netlifyIdentity.currentUser());

  // console.log(user);
  if (!user && window.location.pathname.indexOf("/preview/") == -1) {
    netlifyIdentity.open();
  }

  const screenshotUrl = "https://image.thum.io/get/wait/12/https://appflows.ai"; //`https://image.thum.io/get/wait/12/${window.location.toString()}`;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location.pathname);
    if (location.pathname == "/projects") {
      setProject(null);
    } else {
      const tmpProjects = projectRepository.loadProjects();
      const projectId = params["id"] || null;
      const tmpProj = projectId
        ? projects.filter((p) => p.id === projectId)[0]
        : null;
      setProjects(tmpProjects);
      setProject(tmpProj);
    }
  }, [location]);

  const saveProject = (myProjects, proj) => {
    projectRepository.saveProject(myProjects, proj);
    setProjects(myProjects);
    setProject(proj);
  };

  useEffect(() => {
    //document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const navbarBurger = document.querySelectorAll(".navbar-burger")[0];
    
    
    if (navbarBurger){
      navbarBurger.addEventListener("click", () => {
        navbarBurger.classList.toggle("is-active");
        const navbarMenu = document.querySelectorAll(".navbar-menu")[0];
        navbarMenu.classList.toggle("is-active");
      });    
    }
  });

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${project?.name || "Loading"}`} />
        <meta property="og:url" content={`${window.location.toString()}`} />
        <meta property="og:image" content={screenshotUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${project?.name || "Loading"}`} />
        <meta name="twitter:url" content={`${window.location.toString()}`} />
        <meta name="twitter:image" content={screenshotUrl} />
      </Helmet>
      <nav
        className="navbar is-fixed-top is-mobile has-background-custom"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item is-color-light">
            <Link className="navbar-item" to={user ? "/projects" : "/"}>
              <img src="/appflows-logo.svg" style={{ width: "120px" }} />
            </Link>
          </div>
          <a
            role="button"
            class="navbar-burger has-text-dark"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start">
           
            <Link to={`/projects/`} className="navbar-item pr-0">
              Projects
            </Link>
            {project ? (
              <>
                <Link to={`/projects/${projectId}`} className="navbar-item">
                  /&nbsp;&nbsp;{project ? project.name : "Unknown"}
                </Link>
                
                <Link
                  to={`/projects/${projectId}/strategy`}
                  className="navbar-item is-hidden"
                >
                  The Big Picture
                </Link>
                <Link
                  to={`/projects/${projectId}/flows`}
                  className="navbar-item is-hidden"
                >
                  User Flows
                </Link>
                <Link
                  to={`/projects/${projectId}/growth`}
                  className="navbar-item is-hidden"
                >
                  Growth Plan
                </Link>
              </>
            ) : null}
          </div>
          <div className="navbar-end">
            {project ? (
              <div class="navbar-item">
                <ShareDropdown
                  project={project}
                  onComplete={(proj) => saveProject(projects, proj)}
                />
              </div>
            ) : null}

            {user ? (
              dropDown()
            ) : (
              <div className="navbar-item p-1 pr-3">
                <button
                  className="navbar-item button is-primary has-text-light"
                  onClick={() => netlifyIdentity.open()}
                >
                  Sign Up or Login
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
      <div id="detail" className="pt-6">
        {user || window.location.pathname.indexOf("/preview/") > -1 ? (
          <Outlet context={{ projects, setProjects, project, setProject }} />
        ) : (
          emptyState()
        )}
      </div>
      <footer className="footer no-print has-text-light mt-6">
        <div className="content">
          <div className="columns">
            <div className="column ">
              <p>
                <a href="https://appflows.ai">
                  <img
                    src="/appflows-logo-dark.svg"
                    alt="appflows"
                    style={{ height: "25px" }}
                  />
                </a>
                <br />
                <div className="is-flex is-vcentered pt-1">
                Made by{" "}                
                <a
                  href="https://pocketworks.co.uk"
                  className="has-text-primary"
                >
                  <img className="" src="https://www.datocms-assets.com/20955/1579120308-secondary-logo-white-v3.png" style={{ height: "1.2rem", position: "relative", bottom: "-1px", "padding-left": "0.5rem" }} />
                </a>
                , we help businesses make apps that make a difference.
                </div>
              </p>
              <p>Copyright &copy; {new Date().getFullYear()} Pocketworks Mobile Ltd</p>
              <p className="is-size-7">
                Pocketworks Mobile Ltd
                <br />
                AHQ, 10-12 East Parade
                <br />
                Leeds, LS1 2BH
                <br />
                UK registered company no. 08041956
                <br/><br/><a className="has-text-white" href="mailto:hello@pocketworks.co.uk">hello@pocketworks.co.uk</a>
              </p>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );

  function  dropDown() {
    return (
      <div class="navbar-item has-dropdown is-hoverable ">
        <a class="navbar-link is-arrowless">
          <FontAwesomeIcon
            className="is-centered"
            icon={byPrefixAndName.fas["user"]}
          />
        </a>

        <div class="navbar-dropdown is-right">
          <Link class="navbar-item" to="/projects">
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["list"]}
            />
            Projects
          </Link>
          <Link class="navbar-item" to="/projects/backup">
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["download"]}
            />{" "}
            Backup &amp; Restore
          </Link>
          <div className="navbar-item columns is-hidden">
            <div className="column">Cloud Sync</div>
            <div className="column has-text-right is-size-7">(unavailable)</div>
          </div>
          <hr class="navbar-divider" />

          <a class="navbar-item" href="mailto: hello@pocketworks.co.uk">
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["envelope"]}
            />{" "}
            Report an issue
          </a>
          <a class="navbar-item" href="https://appflows.ai" target="_blank">
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["home"]}
            />{" "}
            AppFlows Home Page
          </a>
          <a
            class="navbar-item"
            href="https://pocketworks.co.uk"
            target="_blank"
          >
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["handshake"]}
            />{" "}
            Need an app advisor?
          </a>

          <hr class="navbar-divider" />
          <button
            className="navbar-item is-fullwidth"
            onClick={() => {
              netlifyIdentity.logout();
              netlifyIdentity.open();
            }}
          >
            <FontAwesomeIcon
              className="pr-2 is-centered"
              icon={byPrefixAndName.fal["power-off"]}
            />{" "}
            Logout
          </button>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <div className="section content has-text-centered">
        <p className="title subtitle">
          👆🏻 Please login or register to get started
        </p>
      </div>
    );
  }
}
