import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { useScreenshot, createFileName } from "use-react-screenshot";
import imageKeywords from "../imageIndex-sketch.json";
import { formatCurrency } from "../view-util.js";
import { ProjectRepository } from "../ProjectRepository.js";
import canvasTemplate from "../canvas-template.json";


const UserFlowView = ({
  settings: settings,
  getPDF: getPDF,
  title: title,
  description: description,
  flows: flows,
  updateImagePickerStory: updateImagePickerStory,
  imageKeywords: imageKeywords,
  showPoints: showPoints,
  showPrice: showPrice,
  totalPrice: totalPrice,
  totalPoints: totalPoints,
  project: project,
  hideBigPicture: hideBigPicture = true,
  isPreviewMode: isPreviewMode = false,
  onUpdateProject: onUpdateProject,
  onTabSelected: onTabSelected,
  onStrategyFieldSelected: onStrategyFieldSelected,
  initialSelectedTab: initialSelectedTab = "flows-tab",
}) => {
  // useEffect(()=>{
  //   console.log("Rendering UserFlowView")
  // },[ref])

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const netlifyIdentity = require("netlify-identity-widget");

  const getImage = () => {
    document.getElementById("pdf-button").classList.toggle("is-hidden");
    document.getElementById("copy-button").classList.toggle("is-hidden");
    document.getElementById("credits-text").classList.toggle("is-hidden");
    takeScreenshot(ref.current).then((s) => {
      fetch(s).then((d) => {
        console.log(s);
        d.blob().then((b) => {
          document.getElementById("pdf-button").classList.toggle("is-hidden");
          document.getElementById("copy-button").classList.toggle("is-hidden");
          document.getElementById("credits-text").classList.toggle("is-hidden");
          navigator.clipboard.write([new ClipboardItem({ [b.type]: b })]);
        });
      });
    });
  };

  const FlowBotDropdown = () => {
    return (
      <div className="dropdown is-hoverable is-right is-pulled-right no-print">
        <div className="dropdown-trigger2">
          <button
            className="button is-text has-text-warning-60 m-2"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon
              className="pl-1 pr-1"
              size="lg"
              icon={byPrefixAndName.fas["sparkles"]}
            />
            
            <FontAwesomeIcon
              className="pl-1"
              icon={byPrefixAndName.fal["chevron-down"]}
            />
          </button>
        </div>
        <div className="dropdown-menu">
          <div className="dropdown-content">
            <a href="#" className="dropdown-item">
              <FontAwesomeIcon
                className="pr-2"
                icon={byPrefixAndName.fal["lightbulb"]}
              />
              Suggest improvements to strategy
            </a>
            <a href="#" className="dropdown-item">
              <FontAwesomeIcon
                className="pr-2"
                icon={byPrefixAndName.fal["list-check"]}
              />
              Suggest missing features
            </a>
            <a href="#" className="dropdown-item">
              <FontAwesomeIcon
                className="pr-2"
                icon={byPrefixAndName.fal["calculator"]}
              />
              Estimate the cost
            </a>
          </div>
        </div>
      </div>
    );
  };

  const toggleTabs = (selectedName) => {
    setSelectedTab(selectedName);
    // ["flows-tab", "strategy-tab", "estimate-tab"].map((n) => {
    //   const tab = document.getElementById(`${n}-item`);
    //   const content = document.getElementById(`${n}-content`);
    //   if (n === selectedName && content && tab) {
    //     tab.classList.add("is-active");
    //     content.classList.remove("is-hidden");
    //   } else if (content && tab) {
    //     tab.classList.remove("is-active");
    //     content.classList.add("is-hidden");
    //   }
    //   if(onTabSelected) onTabSelected(selectedName)
    // });
    if (onTabSelected) onTabSelected(selectedName);
    // document.getElementById("flows-tab").classList.toggle("is-hidden");
    // document.getElementById("strategy-tab").classList.toggle("is-hidden");
    // document.getElementById("flows-tab-item").classList.toggle("is-active");
    // document.getElementById("strategy-tab-item").classList.toggle("is-active");
  };

  function EditableParagraph(props) {
    return (
      <pre        
        className={`pl-0 pt-0 ${props.className} ${
          props.disabled ? "contenteditable-paragraph-readonly" : "contenteditable-paragraph"
        } ${
          !props.disabled && (!props.text || props.text?.length == 0)
            ? "empty"
            : ""
        }`}
        contenteditable={props.disabled ? "false" : "true"}
        plaintext-only="true"
        onBlur={props.onBlur}
        onMouseOver={props.onHover}
        onMouseOut={props.onBlur}
        dangerouslySetInnerHTML={{__html: props.text}}
        // dangerouslySetInnerHTML={{ __html: props.text.replace(/\n/,"<br />") }}
      />
    );
  }

  const updateStrategy = (field, value) => {
    console.log(`Updating ${field} to ${value}`);
    const tmp = structuredClone(project);
    if (!tmp.strategy) tmp.strategy = {};
    tmp.strategy[field] = value;
    if (onUpdateProject) onUpdateProject(tmp);
  };

  const getStrategySection = (name) => {
    for (const section of canvasTemplate.sections) {
      if (section.key === name) {
        return section;
      }
      for (const subsection of section.sections) {
        if (subsection.key === name) {
          return subsection;
        }
      }
    }
    // canvasTemplate.sections.foreach((section,i) => {
    //   //console.log(section.key)
    //   if (section.key === name) {
    //     return section
    //   }
    //   section.sections.map((subsection) => {
    //     if (subsection.key === name) {
    //       return subsection
    //     }
    //   });
    // });
    return null;
  };

  const downloadCSV = (type) => {
    const csvData = flows.map(flow => 
      flow.stories.map(story => ({
        title: story.title,
        description: story.text.replace(/<[^>]*>/g, ''),
        points: story.size,
        epic: flow.name
      }))
    ).flat();

    let csvContent = '';
    
    switch(type) {
      case 'jira':
        csvContent = 'Summary,Description,Story Points,Epic Link\n' + 
          csvData.map(row => 
            `"${row.title}","${row.description}",${row.points},"${row.epic}"`
          ).join('\n');
        break;
      case 'linear':
        csvContent = 'Title,Description,Estimate,Team\n' + 
          csvData.map(row => 
            `"${row.title}","${row.description}",${row.points},"${row.epic}"`
          ).join('\n');
        break;
      // Add other formats as needed
      default:
        csvContent = 'Title,Description,Points,Epic\n' + 
          csvData.map(row => 
            `"${row.title}","${row.description}",${row.points},"${row.epic}"`
          ).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${project.name}-${type}.csv`;
    link.click();
  };

  return (
    <div>
      <div className="tabs is-boxed doc-tabs no-print">
        <ul style={{ border: "0px" }}>
          {!hideBigPicture ? (
            <li
              id="strategy-tab-item"
              className={`${selectedTab == "strategy-tab" ? "is-active" : ""}`}
              data-tab="3"
            >
              <a
                className="has-text-dark"
                onClick={() => toggleTabs("strategy-tab")}
              >
                Big Picture
              </a>
            </li>
          ) : null}
          <li
            id="flows-tab-item"
            className={`${selectedTab == "flows-tab" ? "is-active" : ""}`}
            data-tab="1"
          >
            <a
              className="has-text-dark"
              onClick={() => toggleTabs("flows-tab")}
            >
              User Flows
            </a>
          </li>
          <li
            id="estimate-tab-item"
            className={`${selectedTab == "estimate-tab" ? "is-active" : ""}`}
            data-tab="2"
          >
            <a
              className="has-text-dark"
              onClick={() => toggleTabs("estimate-tab")}
            >
              Estimate
            </a>
          </li>
          <li
            id="share-tab-item"
            className={`${selectedTab == "share-tab" ? "is-active" : ""}`}
            data-tab="4"
          >
            <a
              className="has-text-dark"
              onClick={() => toggleTabs("share-tab")}
            >
              Share
            </a>
          </li>
        </ul>
      </div>
      {/* <FlowBotDropdown/> */}

      <div
        id="flows-tab-content"
        className={`handwriting has-background-white box ${
          selectedTab == "flows-tab" ? "" : "is-hidden"
        }`}
        style={{ "border-radius": "0px 10px 10px 10px" }}
        data-content="1"
      >
        <div ref={ref}>
          <section className="section content">
            <div
              id="credits-text"
              className="has-text-grey is-size-7 is-pulled-left is-hidden"
            >
              Created with appflows.ai
            </div>
            <button
              id="copy-button"
              data-title="Share"
              data-intro="Share by copying an image to clipboard."
              data-step="4"
              className="button is-text is-pulled-right no-print is-size-7"
              onClick={getImage}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <FontAwesomeIcon
                className="pl-1"
                icon={byPrefixAndName.fal["camera"]}
              />
              &nbsp;Copy
            </button>
            <button
              id="pdf-button"
              data-title="PDF Download"
              data-intro="Or download a PDF. You can also Ctrl-P to print."
              data-step="5"
              className="button is-text is-pulled-right no-print is-size-7"
              onClick={getPDF}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <FontAwesomeIcon
                className="pl-1"
                icon={byPrefixAndName.fal["file-pdf"]}
              />
              &nbsp;PDF
            </button>
            <h2 className="subtitle is-size-5 mb-0 pt-4 has-text-dark has-text-weight-normal pb-0">
              User Flows
            </h2>
            <h1 className="title is-1 mt-2 mb-3">{title}</h1>
            {!isPreviewMode ? (
              <div className="box has-background-white-ter is-shadowless no-print">
                <FontAwesomeIcon
                  className="pr-3"
                  icon={byPrefixAndName.fas["circle-2"]}
                />
                Write the user stories to scope out the app, using the editor to
                the right.
              </div>
            ) : null}
            <p className="has-text-grey is-hidden ">
              Last updated:{" "}
              {project?.lastUpdated
                ? `${new Date(
                    project.lastUpdated
                  ).toLocaleDateString()} ${new Date(
                    project.lastUpdated
                  ).toLocaleTimeString()}`
                : "Never"}
            </p>
            <p className="has-text-grey pt-3">
              {settings.showPoints ? `Total size: ${totalPoints} points. ` : ""}
              {settings.showPrice
                ? `Estimated cost: ${formatCurrency(totalPrice)}`
                : ""}
            </p>
            <div
              className="has-text-weight-semibold"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </section>
          {flows.map((flow) => (
            <section className="section content pb-4 pt-0" key={flow.name}>
              <Flow
                flow={flow}
                settings={settings}
                updateImagePickerStory={updateImagePickerStory}
              ></Flow>
            </section>
          ))}
          {flows.length > 0 && settings.showTable ? (
            <EstimateTable flows={flows} settings={settings} />
          ) : null}
          <div
            className={`column mt-6 is-12 box has-background-white-ter is-shadowless no-print`}
          >
            Not sure about your features? We offer professional app design and
            market-validation services,{" "}
            <a href="https://pocketworks.co.uk/services" target="_blank">
              check em' out
            </a>
            .
          </div>
        </div>
      </div>
      <div
        id="strategy-tab-content"
        className={`box handwriting pb-6 ${
          selectedTab == "strategy-tab" ? "" : "is-hidden"
        }`}
        data-content="2"
        style={{ "border-radius": "0px 10px 10px 10px" }}
      >
        <section className="section content">
          <div className="pt-4"></div>
          <h2 className="subtitle is-size-5 mb-0 has-text-dark has-text-weight-normal pb-0">
            The Big Picture
          </h2>
          <h1 className="title is-1 mt-2 mb-3">{project.name}</h1>
          {!isPreviewMode ? (
            <div className="box has-background-white-ter is-shadowless no-print">
              <FontAwesomeIcon
                className="pr-3"
                icon={byPrefixAndName.fas["circle-1"]}
              />
              Write your mobile strategy here to capture your big decisions.
            </div>
          ) : null}

          <div className="columns mt-3 is-multiline is-mobile">
            <div className="column is-10-widescreen is-12-desktop is-12-mobile is-12-tablet">
              <h4 className="has-text-weight-semibold is-color-info-50">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["rocket-launch"]}
                />
                Winning Aspiration
              </h4>

              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["winning-aspiration"]}
                className="is-size-3-widescreen is-size-4-desktop is-size-5-tablet is-size-5-mobile has-text-weight-semibold"
                onBlur={(e) =>
                  updateStrategy("winning-aspiration", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("winning-aspiration")
                    : null
                }
              />
            </div>
            <div className="column is-4"></div>
            <div className="column is-12 mt-3 pb-0">
              <h4 className="has-text-weight-semibold is-color-info-50">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["location"]}
                />
                Where to Play
              </h4>
            </div>
            <div className="column is-6">
              <h5 className="has-text-weight-light is-size-6">Geography</h5>

              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["geography"]}
                onBlur={(e) =>
                  updateStrategy("geography", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("geography")
                    : null
                }
              />
            </div>

            <div className="column is-6">
              <h5 className="has-text-weight-light is-size-6 ">Customer</h5>

              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["customer"]}
                onBlur={(e) => updateStrategy("customer", e.target.textContent)}
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("customer")
                    : null
                }
              />
            </div>
            <div className="column is-6">
              <h5 className="has-text-weight-light is-size-6">Channel</h5>

              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["channel"]}
                onBlur={(e) => updateStrategy("channel", e.target.textContent)}
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("channel")
                    : null
                }
              />
            </div>
            <div className="column is-6">
              <h5 className="has-text-weight-light is-size-6 ">Offer</h5>

              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["offer"]}
                onBlur={(e) => updateStrategy("offer", e.target.textContent)}
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("offer")
                    : null
                }
              />
            </div>
            <div className="column is-8">
              <h4 className="has-text-weight-semibold is-color-info-50 pt-6">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["road"]}
                />
                Capabilities
              </h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["capabilities"]}
                onBlur={(e) =>
                  updateStrategy("capabilities", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("capabilities")
                    : null
                }
              />
            </div>
            <div className="column is-8 mt-3">
              <h4 className="has-text-weight-semibold is-color-info-50">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["trophy"]}
                />
                How to Win
              </h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["how-to-win"]}
                onBlur={(e) =>
                  updateStrategy("how-to-win", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("how-to-win")
                    : null
                }
              />
            </div>
            <div className="column is-8 mt-5">
              <h4 className="has-text-weight-semibold is-color-info-50">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["chart-line-up"]}
                />
                Management Systems
              </h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["management-systems"]}
                onBlur={(e) =>
                  updateStrategy("management-systems", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("management-systems")
                    : null
                }
              />
            </div>
            <div className="column is-12 mt-3">
              <hr />
              <h4 className="has-text-weight-semibold is-color-info-50 pt-6">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["bolt"]}
                />
                What would have to be true for this strategy to work?
              </h4>
            </div>
            <div className="column is-4">
              <h4 className="has-text-weight-light is-size-6">Customers</h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["wwhtbt-customers"]}
                onBlur={(e) =>
                  updateStrategy("wwhtbt-customers", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("wwhtbt-customers")
                    : null
                }
              />
            </div>

            <div className="column is-4">
              <h4 className="has-text-weight-light is-size-6">Company</h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["wwhtbt-company"]}
                onBlur={(e) =>
                  updateStrategy("wwhtbt-company", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("wwhtbt-company")
                    : null
                }
              />
            </div>
            <div className="column is-4">
              <h4 className="has-text-weight-light is-size-6">Competition</h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["wwhtbt-competition"]}
                onBlur={(e) =>
                  updateStrategy("wwhtbt-competition", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("wwhtbt-competition")
                    : null
                }
              />
            </div>
            <div className="column is-12 mt-3">
              <hr />
              <h4 className="has-text-weight-semibold is-color-info-50 pt-6">
                <FontAwesomeIcon
                  className="pr-2"
                  icon={byPrefixAndName.fal["vial-circle-check"]}
                />
                What are the high-risk assumptions, and how can we test?
              </h4>
            </div>
            <div className="column is-6">
              <h4 className="has-text-weight-light is-size-6">
                High Risk Assumptions
              </h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["wwhtbt-barriers"]}
                onBlur={(e) =>
                  updateStrategy("wwhtbt-barriers", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("wwhtbt-barriers")
                    : null
                }
              />
            </div>

            <div className="column is-6">
              <h4 className="has-text-weight-light is-size-6">
                How we'll test them
              </h4>
              <EditableParagraph
                disabled={isPreviewMode}
                text={project?.strategy?.["wwhtbt-tests"]}
                onBlur={(e) =>
                  updateStrategy("wwhtbt-tests", e.target.textContent)
                }
                onHover={(e) =>
                  onStrategyFieldSelected
                    ? onStrategyFieldSelected("wwhtbt-tests")
                    : null
                }
              />
            </div>
            <div
              className={`column mt-6 is-12 box has-background-white-ter is-shadowless no-print`}
            >
              Struggling with your strategy? We can help you figure it out.
              Check out{" "}
              <a href="https://pocketworks.co.uk/services" target="_blank">
                our mobile strategy services
              </a>
              .
            </div>
          </div>
        </section>
      </div>
      <div
        id="estimate-tab-content"
        className={`box handwriting pb-6 ${
          selectedTab == "estimate-tab" ? "" : "is-hidden"
        }`}
        data-content="2"
        style={{ "border-radius": "0px 10px 10px 10px" }}
      >
        <section className="content section">
          <div className="pt-4"></div>
          <div
            id="credits-text"
            className="has-text-grey is-size-7 is-pulled-left is-hidden"
          >
            Created with appflows.ai
          </div>
          <div className="dropdown is-hoverable is-right is-pulled-right no-print">
            <div className="dropdown-trigger">
              <button className="button is-text is-size-7" style={{ textDecoration: "none" }}>
                <FontAwesomeIcon
                  className="pl-1"
                  icon={byPrefixAndName.fal["file-csv"]}
                />
                &nbsp;Export
                <FontAwesomeIcon
                  className="pl-1"
                  icon={byPrefixAndName.fal["chevron-down"]}
                />
              </button>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-content">
                <a className="dropdown-item" onClick={() => downloadCSV('jira')}>
                  <FontAwesomeIcon
                    className="pr-2"
                    icon={byPrefixAndName.fab["jira"]}
                  />
                  Export for Jira
                </a>
                <a className="dropdown-item" onClick={() => downloadCSV('linear')}>
                  <span className="icon pr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="200" height="200" viewBox="0 0 100 100"><path fill="#222326" d="M1.22541 61.5228c-.2225-.9485.90748-1.5459 1.59638-.857L39.3342 97.1782c.6889.6889.0915 1.8189-.857 1.5964C20.0515 94.4522 5.54779 79.9485 1.22541 61.5228ZM.00189135 46.8891c-.01764375.2833.08887215.5599.28957165.7606L52.3503 99.7085c.2007.2007.4773.3075.7606.2896 2.3692-.1476 4.6938-.46 6.9624-.9259.7645-.157 1.0301-1.0963.4782-1.6481L2.57595 39.4485c-.55186-.5519-1.49117-.2863-1.648174.4782-.465915 2.2686-.77832 4.5932-.92588465 6.9624ZM4.21093 29.7054c-.16649.3738-.08169.8106.20765 1.1l64.77602 64.776c.2894.2894.7262.3742 1.1.2077 1.7861-.7956 3.5171-1.6927 5.1855-2.684.5521-.328.6373-1.0867.1832-1.5407L8.43566 24.3367c-.45409-.4541-1.21271-.3689-1.54074.1832-.99132 1.6684-1.88843 3.3994-2.68399 5.1855ZM12.6587 18.074c-.3701-.3701-.393-.9637-.0443-1.3541C21.7795 6.45931 35.1114 0 49.9519 0 77.5927 0 100 22.4073 100 50.0481c0 14.8405-6.4593 28.1724-16.7199 37.3375-.3903.3487-.984.3258-1.3542-.0443L12.6587 18.074Z"/></svg>
                  </span>
                  <FontAwesomeIcon
                    className="pr-2 is-hidden"
                    icon={byPrefixAndName.fal["file-csv"]}
                    style={{ width: "16px" }}
                  />
                  Export for Linear
                </a>
                <a className="dropdown-item" onClick={() => downloadCSV('github')}>
                  <FontAwesomeIcon
                    className="pr-2"
                    icon={byPrefixAndName.fab["github"]}
                  />
                  Export for Github
                </a>
                <a className="dropdown-item" onClick={() => downloadCSV('asana')}>
                  <FontAwesomeIcon
                    className="pr-2"
                    icon={byPrefixAndName.fal["file-csv"]}
                  />
                  Export for Asana
                </a>
              </div>
            </div>
          </div>
          <h2 className="subtitle is-size-5 mb-0 has-text-dark has-text-weight-normal pb-0">
            Estimate
          </h2>
          <h1 className="title is-1 mt-2 mb-3">{project.name}</h1>

          {!isPreviewMode ? (
            <div className="box has-background-white-ter is-shadowless no-print">
              <FontAwesomeIcon
                className="pr-3"
                icon={byPrefixAndName.fas["circle-3"]}
              />
              Add story-points to your stories and use the table to spot the
              risky items. Add estimates by writing point values at the end of
              each story in the editor, such as <code>3pt</code> or{" "}
              <code>8pt</code> etc.
            </div>
          ) : null}

          <EstimateTable
            flows={flows}
            className="mt-3"
            settings={{
              showTable: true,
              showPoints: true,
              showPrice: settings.showPrice,
              pointPrice: settings.pointPrice,
            }}
          />
          <div
            className={`column mt-6 is-12 box has-background-white-ter is-shadowless no-print`}
          >
            Need your app estimating? We offer a fast-track professional
            estimate for £499,{" "}
            <a href="https://pocketworks.co.uk/contact" target="_blank">
              get in touch
            </a>
            .
          </div>
        </section>
      </div>

      <div
        id="share-tab-content"
        className={`box handwriting pb-6 ${
          selectedTab == "share-tab" ? "" : "is-hidden"
        }`}
        data-content="4"
        style={{ "border-radius": "0px 10px 10px 10px" }}
      >
        <section className="content section">
          <div className="pt-4"></div>
          <h2 className="subtitle is-size-5 mb-0 has-text-dark has-text-weight-normal pb-0">
            Share
          </h2>
          <h1 className="title is-1 mt-2 mb-3">{project.name}</h1>
          <p className="is-size-4">
            Now that you've progressed your idea, it's a good time to invite feedback.
            To share your idea, use the{" "}
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["arrow-up-from-bracket"]}
            />{" "}
            button in the navbar.
          </p>
          <blockquote>
            "No matter how brilliant your mind or strategy, if you're playing a
            solo game, you'll always lose out to a team."
            <br />
            <i>-- Reid Hoffman</i>
          </blockquote>
          <p>
            Consider these <b>four next steps</b>:
          </p>
          <h3>
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["circle-1"]}
            />{" "}
            Get an expert opinion
          </h3>
          <p>
            Your Big Picture decisions are where most apps go wrong. It's easy
            to try and appeal to too many people or make too many assumptions
            about what customers need. So, it's a good idea to get others
            involved to scrutinise your strategy and challenge you. If you your colleagues are too close to the business, consider hiring an external consultant.
          </p>

          <h3>
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["circle-2"]}
            />{" "}
            Validate your strategy
          </h3>
          <p>
            There's nothing worse than building an app that nobody uses (believe me, I've done it). A low-cost way to see if your strategy has legs is to validate it.
            It involves running user research to understand competitors, customer
            pain points and market trends. You'll need to share your strategy with a market research or customer research team.
          </p>

          <h3>
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["circle-3"]}
            />{" "}
            Test a prototype
          </h3>
          <p>
            If you're confident in your strategy, consider developing a
            prototype and testing it on real people. This will give you a gold
            mine of information about how appropriate your features are for their needs.
          </p>

          <h3>
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["circle-4"]}
            />{" "}
            Understand development costs
          </h3>
          <p>
            Once you have clarified your strategy and user stories, you can
            invite your internal product team or development partner to estimate
            your project. Use the Estimation tab to quickly learn where the costs are, and then figure out if there is ROI on each feature.            
          </p>

          <h3>
            <FontAwesomeIcon
              className="has-text-info"
              icon={byPrefixAndName.fas["circle-5"]}
            />{" "}
            Export to Jira or other tools
          </h3>
          <p>
            Using the estimate tab, you can also pass your stories to your dev team to load into Jira, Linear, Github or Asana.
          </p>

          <div className="box is-shadowless has-background-white-ter mt-6">
            <div className="columns">
              <div className="column is-narrow">
                <figure className="image is-128x128">
                  <img className="is-rounded" src="https://www.datocms-assets.com/20955/1579120403-tobin.jpg?auto=compress&fit=contain&fm=jpg&invert=false&sat=-5&w=1080" />
                </figure>
              </div>
              <div className="column">
                
                  Hey {netlifyIdentity?.currentUser()?.name || ''}👋🏼 <br/><br/>
                  If you need help with your app, share your project link with me via email and let me know which of the above I can help you with. Feel free to email me a message if you'd like to chat first."
                  <br /><br/>Tobin<br/> Managing Director, Pocketworks<br/>Creator of AppFlows
                  <br/><a  href="mailto:tobin@pocketworks.co.uk">tobin@pocketworks.co.uk</a>


                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const EstimateTable = ({ flows, settings, className }) => {
  return (
    <table className={`${className}`}>
      <thead>
        <tr>
          <th>Section</th>
          <th>Title</th>
          <th>User Story</th>
          {settings.showPoints && <th>Size</th>}
          {settings.showPoints && <th></th>}
          {settings.showPrice && <th>Cost</th>}
        </tr>
      </thead>
      <tbody>
        {flows.map((flow) =>
          flow.stories.map((story) => (
            <tr key={story.text}>
              <th>{flow.name}</th>
              <td dangerouslySetInnerHTML={{ __html: story.title }}></td>
              <td dangerouslySetInnerHTML={{ __html: story.text }}></td>
              {settings.showPoints && <td>{story.size}</td>}
              {settings.showPoints && (
                <td>
                  <div
                    className="sparkline"
                    style={{
                      background:
                        "hsl(0, 8.50%, " + 100 * (1 - story.size / 18) + "%)",
                      marginTop: "0.2em",
                      height: "1.2em",
                      width: story.size * 5 + "px",
                    }}
                  />
                </td>
              )}
              {settings.showPrice && (
                <td>{formatCurrency(story.size * settings.pointPrice)}</td>
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

const Flow = ({ flow, settings, updateImagePickerStory }) => {
  const price = (flow) => {
    return flow.stories.reduce((total, story) => total + story.size, 0);
  };

  if (!flow || !flow.name) return null;

  return (
    <div id="to-print" className="flow is-clearfix paperx cream pt-0">
      <h2 className="title" id={flow.lineNo}>
        {flow.name}
      </h2>
      <p className="has-text-weight-semibold">{flow.description}</p>
      <p>
        {settings.showPoints && (
          <span className="has-text-grey">{price(flow)} points</span>
        )}
        {settings.showPrice && (
          <span className="has-text-grey">
            {" "}
            &pound;{price(flow) * settings.pointPrice}
          </span>
        )}
      </p>
      {flow.stories.map((story) => (
        <Card
          key={story.text}
          story={story}
          updateImagePickerStory={updateImagePickerStory}
        />
      ))}
    </div>
  );
};

export default UserFlowView;

const Card = ({ story, updateImagePickerStory }) => {
  const title = (story) => {
    const matches = story.text.match(/^([^:]+):(.*)/);
    return matches ? [matches[1], matches[2]] : [null, story.text];
  };

  const imgName = (storyText) => {
    const root = "/screens/";
    const candidates = {};

    if (/#[^\s]+/i.test(storyText)) {
      const imageNames = storyText.match(/#([^\s]+)/);
      const imageName = imageNames[1];
      return `${root}${imageName}.png`;
    } else {
      imageKeywords.forEach((entry) => {
        const keywordsFound = entry.keywords.filter((keyword) => {
          const regex = new RegExp(`\\b${keyword.keyword}\\b`, "i");
          return regex.test(storyText);
        });

        if (!candidates[entry.filename]) candidates[entry.filename] = 0;
        candidates[entry.filename] += keywordsFound.length;
      });

      const sortedArray = Object.entries(candidates).sort(
        (a, b) => b[1] - a[1]
      );
      return sortedArray.length > 0
        ? `${root}${sortedArray[0][0]}`
        : `${root}lock-screen.png`;
    }
  };

  const imgNames = (storyText) => {
    const root = "/screens-clean/";
    const candidates = {};
    var hashtags = [];
    var fullImageNames = [];
    const regex = /#[\w-]+/g;
    if (regex.test(storyText)) {
      const imageNames = storyText.match(regex);

      if (imageNames) {
        hashtags = imageNames.map((tag) => tag.substring(1));

        // console.log(hashtags);
        hashtags.map((foundName) => {
          // console.log(`Adding: ${foundName}`);
          const exactMatches = imageKeywords.filter(
            (k) => k.filename === foundName + ".png"
          );
          exactMatches.forEach((m) => {
            fullImageNames.push(`${root}${m.filename}`);
          });
        });

        //return fullImageNames;
      }
    }
    // if we found an exact match on the hash such as #onboarding then
    // we can just return that
    if (fullImageNames.length > 0) return fullImageNames;
    var tmpStoryText = storyText;

    // otheriwse we'll treat the hash as a keyword and search again
    hashtags.forEach((h) => {
      if (h.length > 2) {
        tmpStoryText += ` ${h}`;
      }
    });

    if (true) {
      imageKeywords.forEach((entry) => {
        entry.keywords.forEach((item) => {
          const regex = new RegExp(`\\b${item.keyword}\\b`, "i");
          if (regex.test(tmpStoryText)) {
            if (!candidates[entry.filename]) candidates[entry.filename] = 0;
            candidates[entry.filename] += item.weight;
          }
        });
        // const keywordsFound = entry.keywords.filter((keyword) =>
        //   storyText.toLowerCase().includes(keyword.keyword)
        // );

        // console.log(keywordsFound ? keywordsFound[0] : '')

        // if (!candidates[entry.filename]) candidates[entry.filename] = 0;

        // candidates[entry.filename] += keywordsFound.length;
      });

      const sortedArray = Object.entries(candidates).sort(
        (a, b) => b[1] - a[1]
      );
      return sortedArray.length > 0
        ? [`${root}${sortedArray[0][0]}`]
        : [`${root}lock-screen.png`];
    }
  };

  const text = (storyText) => {
    return storyText ? storyText.replace(/#[^\s]+/g, "") : storyText;
  };

  return (
    <div
      className="flow-card grow"
      style={{ width: story.imageCount * 160 + "px" }}
      id={story.lineNo}
    >
      <div className="columns">
        {imgNames(`${story.title ?? ""} ${story.text}`).map((imageFile) => (
          <div className="column">
            <img
              src={imageFile}
              alt={story.text}
              className="flow-screen"
              onClick={() => {
                updateImagePickerStory ? updateImagePickerStory(story) : null;
              }}
            />
          </div>
        ))}
      </div>

      <h1>{story.title}</h1>
      <div
        className="flow-card-label"
        dangerouslySetInnerHTML={{ __html: story.text }}
      ></div>
    </div>
  );
};
