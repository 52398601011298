import React, { Component, useState, useEffect } from "react";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { truncate } from "./view-util.js";
import { ProjectRepository } from "./ProjectRepository.js"

const Projects = () => {
  const [generationState, setGenerationState] = useState("done")
  const [useAI, setUseAI] = useState(true)  
  const {project, setProject, projects, setProjects} = useOutletContext()     
    
  // var projectRepository = new ProjectRepository()
  // var projectText = localStorage.getItem("projects");
  // var projectsList = JSON.parse(projectText);
  // var projectList = projectRepository.loadProjects()
  // this.setState({ projects: projectsList });

  const projectRepository = new ProjectRepository()  

  const getAIData = async (title, description) => {
    //document.querySelector("#please-wait").classList.remove("is-hidden");

    //const userInput = document.querySelector("#user-input").value;
    //document.querySelector("#user-input").classList.add("is-loading");
    const response = await fetch("/.netlify/functions/createFullApp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        input: `Title: ${title}\nDescription: ${description}`,
      }),
    });

    const json = response.json();
    console.log("Got response");
    console.log(json);
    return json;
  };

  const createProject = (id, name, description, strategy, flows) => {
    const proj = {
      id: id,
      name: name,
      description: description,
      strategy: strategy,
      flows: flows,
    };
    var tmp = structuredClone(projects) 
    tmp.push(proj)    
    projectRepository.saveProject(tmp, proj)    
    // localStorage.setItem("projects", JSON.stringify(this.state.projects));
    document.getElementById("new-project-form").classList.remove("is-active");
    setProjects(tmp)
    
  };

  const  newProject = async () => {
    const id = crypto.randomUUID();
    var name = document.getElementById("new-project-name");
    var description = document.getElementById("new-project-description");
    setUseAI(document.getElementById("new-project-ai").checked);

    var aiData = { strategy: null, flows: null };

    if (useAI) {
      try {
        setGenerationState("doing")
        getAIData(name.value, description.value)
          .then((response) => {
            aiData = JSON.parse(response.message);
            //console.log(aiData)
            createProject(
              id,
              name.value,
              description.value,
              aiData.strategy,
              aiData.flows
            );
            setGenerationState("done")
          })
          .catch((error) => {
            setGenerationState("done")
            console.log(error);
            alert(
              "Eek, something went wrong with the AI. Probably a timeout or dodgy AI reponse. If you try again it will probably work."
            );
          });
      } catch (error) {
        setGenerationState("done")
        console.log(error);
        alert(
          "Eek, something went wrong. Probably a timeout or dodgy AI reponse. If you try again it will probably work."
        );
      }
    } else {
      createProject(id, name.value, description.value, null, null);
    }
  };

  const deleteProject = (project) => {
    if (confirm(`Delete project ${project.name}?`)) {
      var tmp = projects.filter(
        (obj) => obj !== project
      );
      projectRepository.saveProjects(tmp)      
      setProjects(tmp)
    }
  }

  const restoreProject = (jsonString) => {
    try {
      const projectData = JSON.parse(jsonString);
      const restoredProject = {
        ...projectData,
        id: crypto.randomUUID() // Override with new ID
      };
      
      var tmp = structuredClone(projects);
      tmp.push(restoredProject);
      projectRepository.saveProject(tmp, restoredProject);
      setProjects(tmp);
      document.getElementById("restore-project-form").classList.remove("is-active");
    } catch (error) {
      alert("Invalid project JSON. Please check the format and try again.");
    }
  };

  const restoreProjectModal = () => {
    return (
      <div className="modal" id="restore-project-form">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Restore Project</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() =>
                document
                  .getElementById("restore-project-form")
                  .classList.toggle("is-active")
              }
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label has-text-dark">Paste Project JSON</label>
              <div className="control">
                <textarea
                  className="textarea"
                  id="restore-project-json"
                  placeholder="Paste the project JSON here"
                  rows="10"
                ></textarea>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="buttons">
              <button
                className="button is-success"
                onClick={() => {
                  const jsonString = document.getElementById("restore-project-json").value;
                  restoreProject(jsonString);
                }}
              >
                Restore Project
              </button>
              <button
                className="button"
                onClick={() =>
                  document
                    .getElementById("restore-project-form")
                    .classList.toggle("is-active")
                }
              >
                Cancel
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  };

  const newProjectModal = () => {
    return (
      <div className="modal" id="new-project-form">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">New Project</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() =>
                document
                  .getElementById("new-project-form")
                  .classList.toggle("is-active")
              }
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label has-text-dark">Name of the app you're planning</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="new-project-name"
                  defaultValue={"Indyflix"}
                  placeholder=""
                />
              </div>
            </div>
            <div className="field pt-4">
              <label className="label has-text-dark">Short description</label>
              <div className="control">
                <textarea
                  className="textarea"
                  id="new-project-description"
                  placeholder="Describe your app idea here in one or two sentances"
                  rows="2"
                  defaultValue={
                    "An app startup that offers similar features to Netflix but differentiates by offering independent filmmaker content."
                  }
                ></textarea>
              </div>
            </div>
            <div className="field pt-4">
              <p className="has-text-weight-bold has-text-dark pb-2 mb-0">
                Feeling lazy?
              </p>

              <div class="field">
                <label class="switch is-outlined is-rounded">
                  <span class="control-label has-text-dark pr-2">
                    <FontAwesomeIcon
                      icon={byPrefixAndName.fas["sparkles"]}
                      size="xl"
                      className="has-text-warning pr-1"
                    />{" "}
                    Let AI do the work for me!{"  "}
                  </span>
                  <input
                    type="checkbox"
                    id="new-project-ai"
                    className="checkbox is-large"
                    defaultChecked={useAI}
                    value="yes"
                    onClick={(e) =>
                      setUseAI(e.currentTarget.checked)
                    }
                  />
                  <span class="check"></span>
                </label>
              </div>
            </div>

            <article
              class={`message is-info pt-3 ${
                generationState == "done" ? "is-hidden" : ""
              }`}
            >
              <div class="message-body">
                <FontAwesomeIcon
                  icon={byPrefixAndName.fas["robot"]}
                  className="has-text-info pr-1 grow-shrink"
                />{" "}
                Robot thinking, please allow up to 10 seconds.
              </div>
            </article>

            <article
              class={`message is-warning pt-3 ${
                generationState == "done" && useAI
                  ? ""
                  : "is-hidden"
              }`}
            >
              <div class="message-body">
                <FontAwesomeIcon
                  icon={byPrefixAndName.fas["warning"]}
                  className="has-text-orange pr-1"
                />{" "}
                Your title and description above will be sent to OpenAI, but not
                stored.
              </div>
            </article>
          </section>
          <footer className="modal-card-foot">
            <div className="buttons">
              <button
                className="button is-success"
                onClick={newProject}
                disabled={generationState != "done"}
              >
                {generationState == "done"
                  ? "Create Project"
                  : "Please Wait..."}
              </button>
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("new-project-form")
                    .classList.toggle("is-active");
                }}
              >
                Cancel
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }

  
    return (
      <>
        <section className=" container is-fullhd pl-6 pr-6">
          <div className="content">
            <article class="message is-danger is-hidden-desktop">
              <div class="message-body">
                <FontAwesomeIcon
                  className="pr-1"
                  icon={byPrefixAndName.fas["desktop"]}
                  size="xl"
                />{" "}
                AppFlows is hard to use on small devices. We'd recommend you try
                it on your laptop or desktop.
              </div>
            </article>
            <h1 className="title">Projects</h1>
            <p>
              <button
                className="button is-primary has-text-white"
                data-intro="<p>Create your first project!</p>"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("new-project-form")
                    .classList.toggle("is-active");
                }}
              >
                 <FontAwesomeIcon
                        className="has-text-white mr-2"
                        icon={byPrefixAndName.fas["plus"]}
                      />
                Create New Project
              </button>
              <div className="dropdown is-hoverable is-right ml-2">
                <div className="dropdown-trigger">
                  <button className="button" aria-controls="dropdown-menu">
                    <FontAwesomeIcon
                      icon={byPrefixAndName.fas["ellipsis"]}
                    />
                  </button>
                </div>
                <div className="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <a 
                      className="dropdown-item"
                      onClick={() =>
                        document
                          .getElementById("restore-project-form")
                          .classList.toggle("is-active")
                      }
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={byPrefixAndName.fas["upload"]}
                      />
                      Restore Project
                    </a>
                  </div>
                </div>
              </div>
            </p>
            <article class="message pb-3">
              <span class="tag has-background-grey-90 has-text-dark-40">
                <FontAwesomeIcon
                  className="pr-1 has-text-dark-60 pr-2"
                  icon={byPrefixAndName.fas["lock"]}
                  size="l"
                />{" "}
                Your data is privately stored on your browser, not sent to
                the cloud.
              </span>
            </article>

            <div className="columns is-multiline">
              {(projects ?? []).map((proj) => (
                <div className="column is-6" key={proj.id}>
                  <div className="box">
                    <button
                      className="button is-text is-pulled-right pl-2 pr-2"
                      onClick={() => deleteProject(proj)}
                      data-cooltipz-dir="bottom"
                      aria-label="Delete this project permanently"
                    >
                      <FontAwesomeIcon
                        className="has-text-grey"
                        icon={byPrefixAndName.fas["trash"]}
                      />
                    </button>
                    <button 
                      className="button is-text is-pulled-right pl-2 pr-2 has-tooltip-bottom has-tooltip-multiline"
                      data-cooltipz-dir="bottom"
                      aria-label="Duplicate this project to create a copy"
                      onClick={() => {
                        const duplicatedProjects = projectRepository.duplicateProject(proj, projects);
                        setProjects(duplicatedProjects);
                      }}
                    >
                      <FontAwesomeIcon
                        className="has-text-grey"
                        size="m"
                        icon={byPrefixAndName.fas["clone"]}
                      />
                    </button>

                    <Link to={`/projects/${proj.id}`}>
                      <h3 className="title has-text-primary">{proj.name}
                      <FontAwesomeIcon
                        className="pl-2"                        
                        icon={byPrefixAndName.fas["arrow-right"]}
                      />
                      </h3>
                    </Link>
                    <p style={{ height: "7rem", overflow: "hidden" }}>
                      {truncate(proj.description, 150)}
                      <br/>
                      {proj.previewId ? (
                        <span 
                          className="tag mt-3 has-background-success-80 has-text-success-40"
                          data-cooltipz-dir="right"
                          aria-label="This project has a shareable preview link"
                        >✓ Shared</span>
                      ) : (
                        <span 
                          className="tag mt-3"
                          data-cooltipz-dir="right" 
                          aria-label="Enable sharing in project settings to create a preview link"
                        >Private</span>
                      )}
                      
                    </p>
                   
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {newProjectModal()}
        {restoreProjectModal()}
      </>
    );  
}

export default Projects;
