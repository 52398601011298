export function truncate(str,len){
  if(str && str.length > len){
    return str.slice(0,len).trim() + '...'
  } 
  return str;
}

export function formatCurrency(number){
  const formattedNumber = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formattedNumber.format(number);
};

export function getPDF(){
  //console.log(ref.current)
  var div = document.getElementById("#to-print");
  //var divContents = ref.current.innerHTML
  // var printWindow = window.open('', '', 'height=400,width=800');
  // printWindow.document.write('<html><head><title>DIV Contents</title>');
  // printWindow.document.write('</head><body >');
  // printWindow.document.write(div.innerHTML);
  // printWindow.document.write('</body></html>');
  // printWindow.document.close();
  window.print();
};