import React, { useRef, useState, useEffect } from "react";
import Editor from "@toast-ui/editor";
import introJs from "intro.js/intro";
import { useParams, Link, useOutletContext } from "react-router-dom";
import imageKeywords from "./imageIndex-sketch.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import Tornpaper from "./tornpaper.js";
import UserFlowView from "./components/UserFlowView.js";
import ShareDropdown from "./components/ShareDropdown.js";
import { formatCurrency, getPDF } from "./view-util.js";
import { parseFlows } from "./FlowLogic.js";
import { ProjectRepository } from "./ProjectRepository.js";
import canvasTemplate from "./canvas-template.json";

const FlowBuilder = ({initialSelectedTab="flows-tab"}) => {
  const params = useParams();
  // const [projects, setProjects] = useState(null);
  // const [project, setProject] = useState(null);
  const [title, setTitle] = useState("New Idea");
  const [selectedStrategyField, setSelectedStrategyField] = useState(null);
  const [selectedStrategyHintSection, setSelectedStrategyHintSection] =
    useState(null);
  const [description, setDescription] = useState(
    "Below are the flows for my app idea, generated with <a href='https://appflows.ai' target='_blank'>appflows.ai</a>."
  );
  const [flows, setFlows] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [editor, setEditor] = useState(null);
  const [settings, setSettings] = useState({
    pointPrice: 850,
    showPrice: false,
    showPoints: false,
    showTable: false,
  });
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab)
  //const [lastLineNo, setLastLineNo] = useState(0)

  var lastLineNo = 0;

  const [currentLineNoState, setCurrentLineNoState] = useState(0);
  var currentLineNo = 0;
  const setCurrentLineNo = (num) => {
    currentLineNo = num;
    setCurrentLineNoState(num);
  };
  const [imagePickerStory, setImagePickerStory] = useState(null);

  const updateImagePickerStory = (story) => {
    console.log("Updating");
    setCurrentLineNo(story.lineNo);
    setImagePickerStory(story);
  };

  const projectRepository = new ProjectRepository();

  const setStrategyHintSection = (name) => {
    canvasTemplate.sections.map((section) => {
      //console.log(section.key)
      if (section.key === name) {
        setSelectedStrategyHintSection(section);
      }
      section.sections.map((subsection) => {
        if (subsection.key === name) {
          setSelectedStrategyHintSection(subsection);
        }
      });
    });
    return null;
  };

  //introJs().addHints()

  // introJs().setOptions({"showBullets": false, "showStepNumbers": true, "showProgress": true, scrollToElement: false, "dontShowAgain": true}).start();
  // introJs().setDontShowAgain(true);
  const { project, setProject, projects, setProjects } = useOutletContext();

  // useEffect(() => {
  //   if (editor && project && projects && project.flows) {      
  //     setProject(project);
  //     console.log(`Setting editor text to ${project.flows.text}`)
  //     editor.setMarkdown(structuredClone(project.flows.text));      
  //     updateText(project.flows.text);
  //   }
  // }, projects);

  useEffect(() => {
    new Tornpaper({ grungeFrequency: 0.02, grungeScale: 2, tornScale: 10 });

    // load project list and assign to state
    // var projectsJson = localStorage.getItem("projects");

    // const tmp = projectsJson ? JSON.parse(projectsJson) : null;
    // if (tmp == null) throw "No projects";
    // console.log("SETUP: SETTING PROJECTS");
    // setProjects(tmp);

    // get the project from URL
    // const projectId = params["id"] || null;
    // const selectedProject = projectId
    //   ? tmp.filter((p) => p.id === projectId)[0]
    //   : null;

    // console.log("SETTING PROJECT");
    // if (selectedProject == null)
    //   throw "Expected a project with id " + projectId;
    // setProject(selectedProject);

    // console.log("Adding click listener");
    // const dropdown = document.querySelector(".dropdown");
    // const func = (event) => {
    //   event.preventDefault();
    //   dropdown.classList.toggle("is-active");
    // };
    // dropdown.addEventListener("click", func);

    //const saved = localStorage.getItem("current-text");
    // const saved = project && project.flows ? project.flows : {
    //   text: `# My Project\n\nInsert description here.\n\n## First User Flow\n\n* User can download from the app store\n* User can sign in`,
    // };
  
    if (editor && project && projects && project.flows) {      
      setProject(project);
      console.log(`Setting editor text to ${project.flows.text}`)
      editor.setMarkdown(structuredClone(project.flows.text));      
      updateText(project.flows.text);
      return 
    }
    
    const newEditor = new Editor({
      el: document.querySelector("#editor"),
      height: window.innerHeight - 200 + "px",
      initialEditType: "markdown",
      previewStyle: "none",
    });

    setEditor(newEditor);
    //newEditor.setSelection(0,1)
    newEditor.on("change", () => {
      if (project) updateText(newEditor.getMarkdown(), projects, project.id);
    });
    newEditor.on("caretChange", (e) => {
      const sel = newEditor.getSelection();
      setCurrentLineNo(sel[0][0]);
      console.log(currentLineNo);
      const el = document.getElementById(`${currentLineNo}`);
      if (el) {
        if (el.tagName == "H1") return;
        // don't want to do anything if the caret moved on the same line
        // console.log("Is " + lineNo + " == " + lastLineNo)
        if (currentLineNo === lastLineNo) {
          return;
        }
        // otherwise grab the current line, scroll to the card and draw attention to it
        // console.log("Setting " + lineNo)
        lastLineNo = currentLineNo;

        //el.classList.remove("flash")
        el.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        el.classList.add("flash");
        setTimeout(() => {
          el.classList.remove("flash");
        }, 3000);
        //el.classList.add('selected')
      }
    });

    return () => {
      // dropdown.removeEventListener("click", func);
    };  
  }, [projects]);

  const insertExample = (e) => {
    const name = e ? e.target.getAttribute("data-example") : "welcome";
    if (e) e.preventDefault();
    fetch(`/examples/${name}.md`)
      .then((r) => r.text())
      .then((text) => {
        updateText(text);
        editor.setMarkdown(text);
      });
  };

  const appendExample = (e) => {
    const name = e.target.getAttribute("data-example");
    e.preventDefault();
    fetch(`/examples/${name}.md`)
      .then((r) => r.text())
      .then((text) => {
        const currentText = project.flows.text || "";
        updateText(currentText + "\n\n" + text);
        editor.setMarkdown(currentText + "\n\n" + text);
      });
  };

  const prependExample = (e) => {
    const name = e.target.getAttribute("data-example");
    e.preventDefault();
    fetch(`/examples/${name}.md`)
      .then((r) => r.text())
      .then((text) => {
        const currentText = project.flows.text || "";
        updateText(text + "\n\n" + currentText);
        editor.setMarkdown(text + "\n\n" + currentText);
      });
  };

  const appendExample1 = (text) => {
    const currentText = project.flows.text || "";
    updateText(currentText + "\n\n" + text);
    editor.setMarkdown(currentText + "\n\n" + text);
  };

  const prependExample1 = (text) => {
    const currentText = project.flows.text || "";
    updateText(text + "\n\n" + currentText);
    editor.setMarkdown(text + "\n\n" + currentText);
  };

  const updateText = (text, myProjects, projectId) => {
    const parsed = parseFlows(text, settings);

    setTitle(parsed.title);
    setDescription(parsed.description);
    setSettings(parsed.settings);
    setFlows(parsed.flows);
    setTotalPoints(parsed.totalPoints);
    setTotalPrice(parsed.totalPrice);
    // if(myProjects) projectRepository.saveProjects(structuredClone(myProjects) ) 
    // return

    //console.log("UPDATED TEXT: ");

    console.log(myProjects);

    if (project === null) console.log("NO PROJECT");
    if (myProjects === null) {
      console.log("NO PROJECTS");
      //console.log(projects)
    }

    if (myProjects != null) {
      myProjects.map((p) => {
        if (p.id === projectId) {
          console.log("SETTING PROJECT TEXT");
          p["flows"] = { text: text };
          setProject((prevProject) => ({
            ...prevProject,
            flows: { text: text },
          }));
          return;
        }
      });
      console.log("WRITING PROJECTS: " + myProjects.length);
      // setProjects(myProjects);
      if (myProjects.length > 0) {
        localStorage.setItem("projects", JSON.stringify(myProjects));
      } else {
        throw "No projects for some reason";
      }
    }
  };

  const viewerTabSelected = (name) => {
    const editorSideBar = document.getElementById("editor-sidebar");
    const strategySideBar = document.getElementById("strategy-sidebar");
    if (name == "strategy-tab") {
      editorSideBar.classList.add("is-hidden");
      strategySideBar.classList.remove("is-hidden");
    } else {
      editorSideBar.classList.remove("is-hidden");
      strategySideBar.classList.add("is-hidden");
    }
  };

  const saveProject = (myProjects, myProj) => {
    projectRepository.saveProject(myProjects, myProj);
    setProjects(myProjects);
    setProject(myProj);
  };

  const replaceImage = (lineNo, newImageName) => {
    console.log("Replacing line " + lineNo + " with " + newImageName);
    var txt = editor.getMarkdown();
    const lines = txt.split("\n");
    const line = lines[lineNo - 1];
    console.log("Line: " + line);
    var tmp = null;
    if (line.match(/\#[^\s]+/)) {
      console.log(line.match(/\#[^\s]+/));
      tmp = line.replace(/\#[^\s]+/, " #" + newImageName.replace(/\.png/, ""));
    } else {
      tmp = line + " #" + newImageName.replace(/\.png/, "");
    }
    if (tmp) {
      console.log("New Line: " + tmp);
      lines[lineNo - 1] = tmp;
      updateText(lines.join("\n"));
      editor.setMarkdown(lines.join("\n"));
      editor.setSelection([lineNo, 0]);
      setImagePickerStory(null);
    }
    //insertExample(lines.join('\n'));
  };

  return (
    <>
      <main>
        <div className="section container is-fluid">
          <ImagePicker
            imageKeywords={imageKeywords}
            selectedStory={imagePickerStory}
            clearSelectedStory={() => setImagePickerStory(null)}
            replaceImage={(newImageName) =>
              replaceImage(currentLineNoState, newImageName)
            }
          />
          <div className="modal is-active1">
            <div className="modal-background"></div>
            <div className="modal-card is-full-width">
              <header className="modal-card-head">
                <h4 className="title m-0 modal-card-title">
                  Best Practice Library
                </h4>
                <button className="delete" aria-label="close"></button>
              </header>
              <section className="modal-card-body">
                <div className="content columns">
                  <div className="columns column is-6">
                    <div className="column is-4">
                      <img
                        src="/screens-sketch/walkthrough-1.png"
                        alt="Onboarding"
                      />
                    </div>
                    <div className="column is-7">
                      <h5 className="title">Onboarding</h5>
                      <p>
                        An onboarding flow that reduces users abandoning your
                        app.
                      </p>
                      <button className="button">Add to Flow</button>
                    </div>
                  </div>
                  <div className="columns column is-6">
                    <div className="column is-4">
                      <img
                        src="/screens-sketch/walkthrough-1.png"
                        alt="Onboarding"
                      />
                    </div>
                    <div className="column is-8">
                      <h5 className="title">Onboarding (Subscription)</h5>
                      <p>
                        An onboarding flow that increases revenue for
                        subscription apps.
                      </p>
                      <button className="button is-primary">Add to Flow</button>
                    </div>
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot is-hidden">
                <div className="buttons">
                  <button className="button is-success">Add to Flows</button>
                  <button className="button">Cancel</button>
                </div>
              </footer>
            </div>
          </div>
          <div className="columns">
            <div className="column is-8">
              <UserFlowView
                settings={settings}
                getPDF={getPDF}
                title={title}
                description={description}
                flows={flows}
                imageKeywords={imageKeywords}
                totalPoints={totalPoints}
                totalPrice={totalPrice}
                showPoints={settings.showPoints}
                showPrice={settings.showPrice}
                formatCurrency={formatCurrency}
                updateImagePickerStory={updateImagePickerStory}
                project={project}
                hideBigPicture={false}
                isPreviewMode={false}
                onUpdateProject={(proj) => saveProject(projects, proj)}
                initialSelectedTab={initialSelectedTab}
                onTabSelected={(name) => viewerTabSelected(name)}
                onStrategyFieldSelected={(name) => {
                  setSelectedStrategyField(name);
                  setStrategyHintSection(name);
                }}
              />
            </div>

            <div className="column no-print is-4 mt-0">
              <div id="editor-sidebar" className={`fixed ${selectedTab == "strategy-tab" ? 'is-hidden' : ''}`}>
                <div className="no-print">
                  <div
                    className="dropdown is-hoverable"
                    data-intro="You can insert some examples from here."
                    data-step="2"
                  >
                    <div className="dropdown-trigger">
                      <button
                        className="button mb-2 is-small"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu-libarary"
                      >
                        <span>Insert from Library</span>
                        <FontAwesomeIcon
                          className="pl-1"
                          icon={byPrefixAndName.fas["plus"]}
                        />
                      </button>
                    </div>
                    <div
                      className="dropdown-menu"
                      id="dropdown-menu-libarary"
                      role="menu"
                    >
                      <div className="dropdown-content">
                        <div className="dropdown-item content">
                          <h4>Basics</h4>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-example="title-description"
                            onClick={prependExample}
                          >
                            App title and description
                          </a>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-example="new-flow"
                            onClick={appendExample}
                          >
                            New Flow
                          </a>
                        </div>
                        <hr className="dropdown-divider" />
                        <div className="dropdown-item content">
                          <h4>App Best Practices</h4>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-example="onboarding-flow"
                            onClick={appendExample}
                          >
                            User Onboarding Flow
                          </a>
                          {/* <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        High-Conversion Subscription Flow
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        In-App Feedback &amp; Rating Flow
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        Win-back Push Campaign Flow
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        GDPR Preferences Flow
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        Delayed Registration Flow
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        Feature Onboarding
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={insertExample}
                      >
                        Oauth-2 Flow
                      </a> */}
                        </div>
                        <hr className="dropdown-divider" />
                        <div className="dropdown-item content">
                          <h4>Example Apps</h4>
                          <p className="has-text-danger">
                            WARNING: replaces your content
                          </p>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-example="welcome"
                            onClick={insertExample}
                          >
                            Full example app with flows
                          </a>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-example="appspark"
                            onClick={insertExample}
                          >
                            AppSpark example
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="editor"
                  className="no-print"
                  data-intro="Start by editing your specification here. The preview to the right will be updated as you type."
                  data-step="1"
                ></div>
              </div>
              <div id="strategy-sidebar" className={`fixed ${selectedTab != "strategy-tab" ? 'is-hidden' : ''}`}>
                <div className="mt-6 content">
                  
                  <div className="box p-6">
                    {selectedStrategyHintSection ? (
                      <>
                      <FontAwesomeIcon
                      className="pr-1 is-pulled-left has-text-info"
                      size="lg"
                      icon={byPrefixAndName.fas["question-circle"]}
                    />
                        <h5>{selectedStrategyHintSection?.name}</h5>
                        <p>
                          {selectedStrategyHintSection?.description ??
                            "Ooops, hint not found."}
                        </p>
                        <p>Example:</p>
                        <blockquote dangerouslySetInnerHTML={{ __html: selectedStrategyHintSection?.example.replace(/\n/g,"<br/>") ?? "None" }}/>
                          
                      </>
                    ) : (
                      <>
                        <h5>Getting started</h5>
                        <p>
                          This section let's you design your strategy using Roger Martin's world-class
                          strategy framework.
                        </p>
                        <p>
                          Click on the boxes to learn more about each field.
                        </p>
                        
                        <p>
                          {" "}
                          For more details, download the{" "}
                          <a
                            href="https://pocketworks.co.uk/resources/strategy-choice-cascade/"
                            target="_blank"
                          >
                            Pocketworks Mobile Strategy guide
                          </a>
                          .
                        </p>

                        <figure className="image">
                          <a
                            href="https://pocketworks.co.uk/resources/strategy-choice-cascade/"
                            target="_blank"
                          >
                            <img src="https://www.datocms-assets.com/20955/1719776497-pocketworks_mockup_strategychoicecascade.png?auto=compress&fm=png&w=600px" />
                          </a>
                        </figure>                        
                      </>
                    )}
                  </div>
                </div>
                <p className="mt-3 is-size-6 is-shadowless has-background-cream has-text-dark">UI too buggy? Try the <Link to={`/projects/${project.id}/strategy/v1`}>legacy strategy editor</Link>.</p>
              </div>
            </div>
          </div>{" "}
        </div>
      </main>
      <footer className="pdf">
        Private &amp; Confidential. Created with{" "}
        <a href="https://appflows.ai">appflows.ai</a>, by Pocketworks
      </footer>
    </>
  );
};

const ImagePicker = ({
  imageKeywords,
  selectedStory,
  clearSelectedStory,
  replaceImage,
}) => {
  //const [currentStory,setCurrentStory] = [useState(selectedStory)]

  return (
    <div className={`modal ${selectedStory ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Select Image <small>({imageKeywords.length})</small> -{" "}
            {selectedStory ? selectedStory.text : "None"}
          </p>
          <button
            class="delete"
            aria-label="close"
            onClick={() => clearSelectedStory()}
          ></button>
        </header>
        <section class="modal-card-body has-text-centered">
          {imageKeywords.map((i) => {
            return (
              <img
                src={`/screens-clean/${i.filename}`}
                className="p-1"
                width="120"
                onClick={() => {
                  console.log("Replacing...");
                  replaceImage ? replaceImage(i.filename) : null;
                }}
              />
            );
          })}
        </section>
        <footer class="modal-card-foot">
          <div class="buttons">
            <button class="button is-success">Save changes</button>
            <button class="button" onClick={() => clearSelectedStory()}>
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default FlowBuilder;
