import React, { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import Editor from "@toast-ui/editor";
import canvasTemplate from "./canvas-template.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { ProjectRepository } from "./ProjectRepository";

const StrategyBuilder = () => {
  const params = useParams();
  //const [projects, setProjects] = useState([]);
  //const [project, setProject] = useState(null);
  const [layout, setLayout] = useState(canvasTemplate);
  const [model, setModel] = useState({
    "winning-aspiration": "",
    geography: "",
    customer: "",
    channel: "",
    offer: "",
    "how-to-win": "",
    "management-systems": "",
  });
  const projectRepository = new ProjectRepository()
  const {project, setProject, projects, setProjects} = useOutletContext();

  useEffect(() => {
    if(project && projects){
      if(project.strategy) { 
        setModel(project.strategy)
        setProject(project)                    
      } 
      else{
        const tmp = structuredClone(project)
        tmp.strategy = model        
        setProject(tmp)                    
      };      
      setLayout(canvasTemplate);
    }  
  }, [projects]);
  

  // useEffect(() => {
  //   // load project list and assign to state
  //   var projectList = localStorage.getItem("projects");
  //   const projects = projectList ? JSON.parse(projectList) : [];

  //   if (projectList.length > 0) {
  //     try {
  //       setProjects(JSON.parse(projectList));
  //     } catch (e) {
  //       setProjects([]);
  //     }
  //   }

  //   // get the project from URL
  //   const projectId = params["id"] || null;
  //   const selectedProject = projectId
  //     ? projects.filter((p) => p.id === projectId)[0]
  //     : null;

  //   // var saved = localStorage.getItem("current-strategy-model");
  //   var saved = selectedProject.strategy;

  //   if (saved) {
  //     setModel(selectedProject.strategy);
  //     setProject(selectedProject);
  //   } else {
  //     // insertExample(null);
  //   }
  //   selectedProject["strategy"] = model;
  //   setProject(selectedProject);
  //   setLayout(canvasTemplate);
  // }, [params]);

  const updateModel = (e) => {
    if (!e.target.attributes["data-key"]) return;

    const key = e.target.attributes["data-key"].value;
    const value = e.target.value;

    // e.target.style.height = 'auto'
    // e.target.style.height = `${e.target.scrollHeight}px`

    if (model[key] === value) return;

    const newModel = { ...model, [key]: value };

    project["strategy"] = newModel;

    projects.map((p) => {
      if (p.id === project.id) {
        p["strategy"] = newModel;
        return;
      }
    });

    setModel(newModel);
    setProject(project);
    setProjects(projects);

    // localStorage.setItem("current-strategy-model", JSON.stringify(newModel));
    //localStorage.setItem("projects", JSON.stringify(projects));
    projectRepository.saveProjects(projects)
  };

  return (
    <div className="App">
      <section className="section content pb-6 canvas">
        <h1 className="title is-1">
          {project ? project.name : "Unknown"}: The Big Picture
        </h1>
        <div className="columns">
          <div className="column is-6">
            <article class="has-text-dark no-print">
              
                Use this canvas to capture the top-line strategy that underpins
                your app. Download the&nbsp;
                <a
                  href="https://pocketworks.co.uk/resources/strategy-choice-cascade/"
                  target="_blank"
                >
                  structure guide
                </a>{" "}
                and&nbsp; 
                <a
                  href="https://pocketworks.co.uk/resources/mobile-strategy-process/"
                  target="_blank"
                >
                  strategic process guide
                </a>
                &nbsp;to learn more.
              
            </article>
          </div>
        </div>
        <div className="columns is-3 is-multiline">
          {layout.sections.map((section) => (
            <div
              key={section.name}
              className={`canvas-section column pb-1 ${
                section.style ? section.style : "is-4"
              }`}
            >
              <div className="box">
                <h4 className="title has-text-primary-50">{section.name}</h4>
                <p className="has-text-primary">{section.description}</p>
                {section.key ? (
                  <textarea
                    key={section.key}
                    data-key={section.key}
                    rows={section.inputRows ?? 2}
                    className="textarea has-text-weight-regular"
                    placeholder={section.example}
                    onKeyUp={updateModel}
                    defaultValue={model[section.key]}
                  ></textarea>
                ) : (
                  <span />
                )}
                <div className="columns is-multiline mb-1">
                  {section.sections.map((subsection) => (
                    <div
                      key={subsection.name}
                      className={`column ${
                        subsection.style ? subsection.style : "is-12"
                      }`}
                    >
                      <h5 className="title has-text-primary-50">
                        {subsection.name}
                      </h5>
                      <p className="has-text-primary">
                        {subsection.description}
                      </p>
                      <textarea
                        key={subsection.key}
                        data-key={subsection.key}
                        rows={subsection.inputRows ?? 2}
                        className="textarea has-text-weight-regular"
                        placeholder={subsection.example}
                        onKeyUp={updateModel}
                        defaultValue={model[subsection.key]}
                      ></textarea>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default StrategyBuilder;
