import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-1b5e9f4f9d/icons";
import { useParams, Link } from "react-router-dom";

//({onChange: onChange, project: project})
const ShareDropdown = ({ project: initialProject, onComplete: onComplete }) => {
  //const state = { project: null, shareFlows: false, shareStrategy: false };
  const [project, setProject] = useState(initialProject);
  const [shareFlows, setShareFlows] = useState(false);
  const [shareStrategy, setShareStrategy] = useState(false);

  useEffect(() => {
    console.log("Setting project");
    console.log(
      `Initial Flows?: ${initialProject?.shareFlows}, Strat?: ${initialProject?.shareStrategy}`
    );
    setProject(initialProject);
  }, [initialProject]);

  const updateCloud = async ({
    projectToUpdate = null,
    isShareFlows = null,
    isShareStrategy = null,
  }) => {
    //setProject(projectToUpdate)

    const proj = structuredClone(projectToUpdate);
    if (proj == null) {
      throw "No project";
    }

    if (!proj["previewId"]) {
      proj.previewId = crypto.randomUUID();
    }

    if (!proj["userId"]) {
      proj.userId = netlifyIdentity.currentUser().id;
    }

    if (isShareFlows != null) {
      console.log(`Setting share flows to ${isShareFlows}`);
      proj.shareFlows = isShareFlows;
      setShareFlows(isShareFlows);
    }
    if (isShareStrategy != null) {
      console.log(`Setting share strat to ${isShareStrategy}`);
      proj.shareStrategy = isShareStrategy;
      setShareStrategy(isShareStrategy);
    }
    console.log(`Flows?: ${proj.shareFlows}, Strat?: ${proj.shareStrategy}`);

    //console.log(project)
    // const funcName =
    //   shareFlows || shareStrategy ? "putPreview" : "deletePreview";

    setProject(proj);
    // /api/preview/upsert
    // `/.netlify/functions/putPreview`
    const response = await fetch(`/api/preview/upsert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project: proj,
      }),
    });

    // const json = response.json();

    if (response.ok) {
      if (!proj.shareFlows && !proj.shareStrategy) {
        proj.previewId = null;
        setProject(structuredClone(proj));
      }
      onComplete(structuredClone(proj));
    }
  };
  return (
    <div class="navbar-item has-dropdown is-hoverable is-right ml-1">
      <div class="dropdown-trigger">
        <a
          className="navbar-link is-arrowless"
          aria-haspopup="true"
          aria-controls="dropdown-menu-share"
        >
          <FontAwesomeIcon
            className={`is-hidden-mobile ${
              project?.shareFlows || project?.shareStrategy
                ? "has-text-success"
                : null
            }`}
            size="l"
            icon={
              project?.shareFlows || project?.shareStrategy
                ? byPrefixAndName.fas["arrow-up-from-bracket"]
                : byPrefixAndName.fas["arrow-up-from-bracket"]
            }
          />
        </a>
      </div>
      <div
        class="navbar-dropdown is-right"
        id="dropdown-menu-share"
        role="menu"
      >
        <div class="dropdown-content dropdown-wide">
          
          <div class="field dropdown-item p-1 pb-1 ml-2">
            <label class="switch is-outlined is-rounded">
              <input
                type="checkbox"
                id="share-flows-check"
                className="checkbox is-large "
                defaultChecked={initialProject?.shareFlows}
                value="yes"
                onClick={(e) => {
                  // setShareFlows(e.currentTarget.checked);
                  // project.shareFlows = e.currentTarget.checked;
                  console.log(e.currentTarget.checked);
                  updateCloud({
                    projectToUpdate: project,
                    isShareFlows: e.currentTarget.checked,
                  });
                }}
              />
              <span class="check"></span>
              <span class="control-label has-text-dark pr-2">
                Enable Sharing{"  "}
              </span>
            </label>
          </div>
          <div class="field dropdown-item p-1 pb-3 ml-2 is-hidden">
            <label class="switch is-outlined is-rounded">
              <input
                type="checkbox"
                id="share-strategy-check"
                className="checkbox is-large"
                defaultChecked={initialProject?.shareStrategy}
                value="yes"
                onClick={(e) => {
                  // setShareStrategy(e.currentTarget.checked);
                  // project.shareStrategy = e.currentTarget.checked;
                  updateCloud({
                    projectToUpdate: project,
                    isShareStrategy: e.currentTarget.checked,
                  });
                }}
              />
              <span class="check"></span>
              <span class="control-label has-text-dark pr-2">
                Big Picture?{"  "}
              </span>
            </label>
          </div>
          <p className="is-size-6 ml-3 has-text-dark">
            This will uplaod your project data to the cloud. It can be viewed by
            anyone with the link. It will be deleted if not viewed for 90 days
            in a row.
          </p>
          <div
            className={`dropdown-item well m-3 has-background-white-ter is-rounded ${
              project?.previewId &&
              (project?.shareFlows || project?.shareStrategy)
                ? ""
                : "is-hidden"
            }`}
          >
            <span className="has-text-weight-semibold">
              Copy the link to share
              {project?.previewId && (project?.shareFlows || project?.shareStrategy) && (
                <button 
                  className="ml-1 button is-small"
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/preview/${project.userId}/${project.previewId}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={byPrefixAndName.fas["copy"]}
                    size="sm"
                  />
                </button>
              )}
            </span>
            <br />
            <div className="is-flex is-align-items-center">              
              <Link
                to={`/preview/${project?.userId}/${project?.previewId}`}
                className="has-text-grey is-size-7 mr-2"
              >
                {project?.previewId &&
                (project?.shareFlows || project?.shareStrategy)
                  ? `${window.location.protocol}//${window.location.host}/preview/${project.userId}/${project.previewId}`
                  : `Enable sharing above to create a read-only link you can share.`}
              </Link>
              
            </div>
          </div>
          <button
            className={`button ml-3 is-small is-primary has-text-white ${
              project?.previewId && project?.shareFlows ? "" : "is-hidden"
            }`}
            onClick={() => {
              updateCloud({ projectToUpdate: project, shareFlows: true });
              alert("Preview updated");
            }}
          >
            <FontAwesomeIcon
              className={`pr-2 has-text-white`}
              icon={byPrefixAndName.fal["refresh"]}
              size="lg"
            />
            Update Preview
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareDropdown;
